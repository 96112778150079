import { gql, useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { TODO_FIELDS } from "../../../utils/fragments";
import Loading from "../../../components/Loading/Loading";
import useCorpPlan from "../../../hooks/useCorpPlan";
import _ from "lodash";
import TodosTable from "../../../components/Todos/TodosTable";

const TodoStep = ({ userIds, org, canEdit, category }) => {
  const variables = { users: userIds, organization: org.id, category };

  const { subscribeToMore, data, loading } = useQuery(TODO_STEP_QUERY, { variables });
  const { corpForSelectedYear } = useCorpPlan({ fiscalYear: org.fiscalYear, orgId: org.id });

  useEffect(
    () =>
      subscribeToMore({
        document: TODO_STEP_SUBSCRIPTION,
        variables: { users: userIds, category },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const todo = subscriptionData.data.todoPayload.todoMutated;
          const action = subscriptionData.data.todoPayload.action;
          let newTodos;
          switch (action) {
            case "update":
              newTodos = prev.todos.map((t) => (t.id === todo.id ? todo : t));
              break;
            case "delete":
              newTodos = prev.todos.filter((t) => t.id !== todo.id);
              break;
            case "create":
              newTodos = [...prev.todos, todo];
              break;
            default:
              break;
          }

          return Object.assign({}, prev, {
            todos: newTodos,
          });
        },
      }),
    []
  );

  if (loading) return <Loading />;

  return (
    <div>
      <TodosTable todos={data.todos} issues={category === "issue"} showAccountable planId={corpForSelectedYear.id} canEdit={canEdit} />
    </div>
  );
};

export default TodoStep;

const TODO_STEP_QUERY = gql`
  ${TODO_FIELDS}
  query TodoStepQuery($organization: ID!, $users: [ID!], $category: String) {
    todos(organization: $organization, users: $users, category: $category) {
      ...TodoFields
    }
  }
`;

const TODO_STEP_SUBSCRIPTION = gql`
  ${TODO_FIELDS}
  subscription OnTodoMutation($users: [ID!], $category: String) {
    todoPayload(users: $users, category: $category) {
      todoMutated {
        ...TodoFields
      }
      action
    }
  }
`;
