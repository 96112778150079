import React from "react";
import "./NewCalendar.scss";

import Calendar from "react-calendar";

// Needed so we can apply our custom styles from the above stylesheet separate from its parent component's
const NewCalendar = (props) => {
  return <Calendar {...props} />;
};

export default NewCalendar;
