import React, { useEffect, useMemo, useState, useCallback } from "react";
import styles from "./KanbanBoard.module.scss";
import _ from "lodash";
import { v4 } from "uuid";
import { SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLazyQuery } from "@apollo/client";

import { CircularProgress, IconButton, Typography, useTheme } from "@material-ui/core";
import { AddBox, ChevronRight, ExpandMore } from "@material-ui/icons";

import KanbanCard from "./KanbanCard";

const KanbanColumn = ({
  column = {},
  filters = {},
  canFetch = false,
  items = [],
  handleNewlyLoadedItems = () => {},
  expanded = true,
  handleExpand = () => {},
  columnDataQuery,
  columnDataQueryVarDict,
  queryName,
  total = 0,
  handleSetTotal = () => {},
  cardDispIdFunc,
}) => {
  const { id: status, name } = column;

  // const loadingRef = useRef(false);

  const theme = useTheme();

  const [nextCursor, setNextCursor] = useState();

  const [fetchData, { data, loading }] = useLazyQuery(columnDataQuery, {
    variables: { [_.get(columnDataQueryVarDict, "status", "status")]: status },
    fetchPolicy: "network-only", // Used for first execution
  });

  const itemIds = useMemo(() => {
    return items.map((item) => item.id);
  }, [items]);

  const { setNodeRef, setActivatorNodeRef, listeners, transform, transition, isDragging } = useSortable({
    id: status,
    data: {
      type: "Column",
      column: { ...column, items },
    },
    disabled: !expanded,
  });

  const dndStyle = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  // const fetchMoreDataTest = useCallback(() => {
  //   console.log("FETCH MORE DATA - TEST");

  //   // if (loadingRef.current) {
  //   //   console.log("ALREADY FETCHING DATA");
  //   //   return;
  //   // }

  //   // loadingRef.current = true;

  //   return setTimeout(() => {
  //     setItems((prevItems) => {
  //       const statusItems = _.filter(prevItems, ["status", status]);
  //       const lastStatusItem = _.maxBy(statusItems, "position");
  //       const lastStatusItemPos = _.get(lastStatusItem, "position", 0);

  //       let additions = [];
  //       for (let i = 1; i <= 20; i++) {
  //         const id = v4();
  //         additions.push({ id, value: `Todo ${id.substring(0, 5).toUpperCase()}`, status, position: lastStatusItemPos + i * 1000 });
  //       }

  //       return [...prevItems, ...additions];
  //     });

  //     // loadingRef.current = false;
  //   }, 500);
  // }, [setItems, status]);

  // useEffect(() => {
  //   fetchMoreDataTest();
  // }, []);

  useEffect(() => {
    if (data) {
      const queryRes = _.get(data, queryName, {});
      const nextCursorInt = _.get(queryRes, "nextCursor", null);

      /* *Note about an unavoidable behavior with query: when an item x is moved to a different column, 
      // "data" is updated such that item x in the items array now has a different status hence we want to avoid re-passing back
      // the data to the parent component when this happens via checking the cursor */
      if (nextCursorInt !== nextCursor) {
        setNextCursor(nextCursorInt);

        const itemsArr = _.get(queryRes, "items", []);
        handleNewlyLoadedItems(itemsArr);

        const totalItems = _.get(queryRes, "totalItems", 0);
        handleSetTotal(totalItems);
      }
    }
  }, [data, nextCursor, queryName]);

  useEffect(() => {
    if (canFetch) {
      setNextCursor();
      fetchData({
        variables: { ...filters, cursor: null },
      });
    }
  }, [canFetch, filters, fetchData]);

  const hasMore = _.get(data, [queryName, "hasMore"], true); // show loading by default

  return (
    <div
      ref={setNodeRef}
      className={expanded ? styles.column : ""}
      style={{
        // backgroundColor: theme.palette.grey[300],
        visibility: isDragging ? "hidden" : "visible",
        ...dndStyle,
      }}
    >
      <div
        ref={setActivatorNodeRef}
        {...listeners}
        className={expanded ? styles.columnHeader : styles.columnHeaderCollapsed}
        style={{
          padding: theme.spacing(1),
          marginBottom: theme.spacing(1),
          backgroundColor: theme.palette.grey[300],
        }}
      >
        <IconButton
          onPointerDown={(e) => e.stopPropagation()} //alternative to sensors
          onClick={handleExpand}
          className={styles.columnHeaderBtn}
        >
          {expanded ? <ExpandMore /> : <ChevronRight />}
        </IconButton>
        <Typography style={{ flexGrow: expanded ? 1 : 0 }}>
          <strong>{name || _.startCase(status)}</strong>
        </Typography>
        <Typography
          style={{
            marginLeft: expanded ? theme.spacing(2) : 0,
            marginTop: expanded ? 0 : theme.spacing(2),
            marginRight: theme.spacing(1),
          }}
        >
          {total}
        </Typography>
        {/* {expanded && (
          <IconButton
            // onPointerDown={(e) => e.stopPropagation()} // alternative to sensors
            onClick={() => console.log("ADD ITEM TEST")}
            className={styles.columnHeaderBtn}
          >
            <AddBox />
          </IconButton>
        )} */}
      </div>
      {!isDragging && expanded && (
        <div
          id={`cards-container-${status}`}
          style={{
            overflowY: "auto",
            height: "100%",
          }}
        >
          <SortableContext items={itemIds} strategy={verticalListSortingStrategy}>
            <InfiniteScroll
              dataLength={items.length}
              // next={() => fetchMoreDataTest()}
              // hasMore={true}
              next={() => {
                fetchData({
                  variables: { ...filters, cursor: nextCursor },
                });
              }}
              hasMore={hasMore}
              loader={
                <div style={{ margin: "0 auto" }}>
                  <CircularProgress />
                </div>
              }
              scrollableTarget={`cards-container-${status}`}
              className={styles.columnCards}
              style={{
                gap: theme.spacing(1),
                padding: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px`,
                overflow: "hidden", // prevents extra scrollbar from appearing for a split-second
              }}
            >
              {items.map((item) => {
                const { id } = item;

                return <KanbanCard key={id} item={item} dispIdFunc={cardDispIdFunc} />;
              })}
            </InfiniteScroll>
          </SortableContext>
        </div>
      )}
    </div>
  );
};

export default KanbanColumn;
