import React, { useEffect, useState, useMemo, useContext } from "react";
import styles from "./Dashboard.module.scss";
import _ from "lodash";
import gql from "graphql-tag";
import { getCurrentQuarter } from "../../utils/dates";
import { useDepartmentFilter } from "../../context/departmentFilterContext";
import { useMutation, useQuery } from "@apollo/client";
import { SnackbarContext } from "../../context/snackbarContext";
import { indexToLetter } from "../../utils/misc";
import useCorpPlan from "../../hooks/useCorpPlan";

import { Grid, MenuItem, TextField, Typography, useTheme } from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiRhombusOutline, mdiRhombusSplitOutline, mdiCheckboxMarkedOutline, mdiAlertDecagramOutline } from "@mdi/js";

import KanbanBoard from "../../components/KanbanBoard/KanbanBoard";
import SelectCorpYear from "../../components/SelectCorpYear/SelectCorpYear";
import Menu from "../../components/Menu/Menu";

const COLUMNS = [
  { id: "on hold", name: "On Hold" },
  { id: "not started", name: "Not Started" },
  { id: "in progress", name: "In Progress" },
  { id: "roadblocked", name: "Roadblocked" },
  { id: "completed", name: "Completed" },
];

const ITEM_FIELD_KEY_DICT = {
  rock: {
    position: "kanbanPosition",
    status: "kanbanStatus",
  },
  todo: {
    position: "kanbanPosition",
    status: "kanbanStatus",
  },
  issue: {
    position: "kanbanPosition",
    status: "kanbanStatus",
  },
  successCriteria: {
    position: "kanbanPosition",
    status: "kanbanStatus",
  },
};

const ITEM_FIELD_VAL_DICT = {};

const Kanban = ({ org, filterUser, filterType }) => {
  const theme = useTheme();

  const fiscalYear = org.fiscalYear;
  const { corpForSelectedYear } = useCorpPlan({ orgId: org.id, fiscalYear }); // is the current year one year corp plan by default
  const { departmentFilter } = useDepartmentFilter();

  const filters = useMemo(() => {
    return {
      organization: org.id,
      users: [...(filterUser ? [filterUser.id] : [])],
      sharedPlanId: departmentFilter.sharedPlanId,
      oneYearCorpPlan: corpForSelectedYear.id,
      type: filterType,
    };
  }, [org, filterUser, filterType, departmentFilter, corpForSelectedYear]);

  const renderItemDisplayId = ({ type, dispId }) => {
    let ret;
    switch (type) {
      case "issue":
        ret = (
          <>
            <Icon path={mdiAlertDecagramOutline} size={0.75} />
            {`I${dispId}`}
          </>
        );
        break;
      case "todo":
        ret = (
          <>
            <Icon path={mdiCheckboxMarkedOutline} size={0.75} />
            {`T${dispId}`}
          </>
        );
        break;
      case "rock":
        ret = (
          <>
            <Icon path={mdiRhombusOutline} size={0.75} />
            {`R${dispId}`}
          </>
        );
        break;
      case "successCriteria":
        const [rockNum, scNum] = dispId.split("-");
        ret = (
          <>
            <Icon path={mdiRhombusSplitOutline} size={0.75} />
            {`R${rockNum}${indexToLetter(parseInt(scNum))}`}
          </>
        );
        break;
      default:
    }

    return <div style={{ display: "flex", alignItems: "center", gap: theme.spacing(0.5) }}>{ret}</div>;
  };

  return (
    <div
      style={{
        height: `calc(100% - ${theme.spacing(2)}px)`,
        overflowX: "auto",
        overflowY: "hidden",
        margin: `${theme.spacing(2)}px ${theme.spacing(3)}px 0`,
        paddingBottom: theme.spacing(3),
      }}
    >
      <KanbanBoard
        columns={COLUMNS}
        filters={filters}
        itemFieldKeyDict={ITEM_FIELD_KEY_DICT}
        itemFieldValDict={ITEM_FIELD_VAL_DICT}
        columnDataQuery={GET_KANBAN_STATUS_DATA}
        columnDataQueryVarDict={GET_KANBAN_STATUS_DATA_VARS}
        updateItemMutation={MOVE_KANBAN_CARD}
        updateItemMutationVarDict={MOVE_KANBAN_CARD_VARS}
        queryName="kanbanStatusData"
        mutationName="moveKanbanCard"
        cardDispIdFunc={renderItemDisplayId}
      />
    </div>
  );
};

export default Kanban;

const MOVE_KANBAN_CARD = gql`
  mutation moveKanbanCardMutation($id: ID!, $type: String!, $kanbanStatus: String, $targetPosition: Int!) {
    moveKanbanCard(id: $id, type: $type, kanbanStatus: $kanbanStatus, targetPosition: $targetPosition) {
      id
      type
      subValue
      value
      kanbanStatus
      kanbanPosition
      dispId
      targetDate
      plan {
        id
        shortName
        departmentName
        color
      }
      user {
        name {
          first
          last
        }
        profilePicture
      }
    }
  }
`;

const MOVE_KANBAN_CARD_VARS = {
  status: "kanbanStatus",
  position: "targetPosition",
};

const GET_KANBAN_STATUS_DATA = gql`
  query kanbanStatusDataQuery(
    $organization: ID!
    $users: [ID!]
    $kanbanStatus: String!
    $type: String
    $oneYearCorpPlan: ID
    $sharedPlanId: ID
    $cursor: Int
  ) {
    kanbanStatusData(
      organization: $organization
      users: $users
      kanbanStatus: $kanbanStatus
      type: $type
      oneYearCorpPlan: $oneYearCorpPlan
      sharedPlanId: $sharedPlanId
      cursor: $cursor
    ) {
      items {
        id
        subValue
        value
        type
        kanbanStatus
        kanbanPosition
        dispId
        targetDate
        plan {
          id
          shortName
          departmentName
          color
        }
        user {
          name {
            first
            last
          }
          profilePicture
        }
      }
      nextCursor
      hasMore
      totalItems
    }
  }
`;

const GET_KANBAN_STATUS_DATA_VARS = {
  status: "kanbanStatus",
};
