import React from "react";
import styles from "./UserAvatars.module.scss";
import _ from "lodash";
import { Tooltip, Avatar, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";

const UserAvatars = ({ users, small, hidden, maxUsersVisible }) => {
  const userValues = (
    <List dense>
      {!_.isEmpty(users) &&
        users.map(({ id, name, profilePicture }, idx) => {
          return (
            <ListItem key={idx}>
              <ListItemIcon>
                <Avatar key={id} src={profilePicture} alt={`${name.first} ${name.last}`} className={styles.avatar}>
                  {name.first[0]}
                  {name.last[0]}
                </Avatar>
              </ListItemIcon>
              <ListItemText>
                {name.first} {name.last}
              </ListItemText>
            </ListItem>
          );
        })}
    </List>
  );

  if (_.isEmpty(users) && !hidden)
    return (
      <Tooltip title="No user assigned">
        <AvatarGroup max={99} className={styles.center}>
          <Avatar className={small ? styles.avatarNoneSmall : styles.avatarNone}>?</Avatar>
        </AvatarGroup>
      </Tooltip>
    );

  return (
    <Tooltip title={userValues} interactive>
      <AvatarGroup max={99} className={styles.center}>
        {users &&
          users.map(({ id, name, profilePicture }, idx) => {
            if (!_.isNil(maxUsersVisible) && idx + 1 > maxUsersVisible) return null;
            return (
              <Avatar
                key={idx}
                src={profilePicture}
                alt={`${name.first} ${name.last}`}
                className={small ? styles.avatarSmall : styles.avatar}
              >
                {name.first[0]}
                {name.last[0]}
              </Avatar>
            );
          })}
        {users && !_.isNil(maxUsersVisible) && users.length > maxUsersVisible && (
          <Avatar src="" className={small ? styles.avatarRemainSmall : styles.avatarRemain}>
            +{users.length - maxUsersVisible}
          </Avatar>
        )}
      </AvatarGroup>
    </Tooltip>
  );
};

export default UserAvatars;
