import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { useQuery, useSubscription } from "@apollo/client";
import gql from "graphql-tag";
import { Routes, Route, useLocation, Navigate, Outlet } from "react-router-dom";
import { ThemeProvider, StylesProvider } from "@material-ui/core/styles";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import theme from "./utils/theme";
import jwt_decode from "jwt-decode";

import { SnackbarContextProvider } from "./context/snackbarContext";
import { useAuth } from "./context/authContext";
import { useDepartmentFilter } from "./context/departmentFilterContext";
import { LoadingContext } from "./context/loadingContext";

import { CssBaseline } from "@material-ui/core";

import Layout from "./layout/Layout";
import { Error, SelectOrganization, Signin, Signup } from "./views";
import Loading from "./components/Loading/Loading";
import Privacy from "./views/Legal/Privacy/Privacy";
import Security from "./views/Legal/Security/Security";
import Terms from "./views/Legal/Terms/Terms";
import Pricing from "./views/Legal/Pricing/Pricing";
import PasswordReset from "./views/PasswordReset/PasswordReset";
import VerifyEmail from "./views/VerifyEmail/VerifyEmail";
import OnBoard from "./views/OnBoard/OnBoard";
import RequireAuth from "./components/Routes/RequireAuth";
import AuthRedirect from "./components/Routes/AuthRedirect";
import RequireOnboard from "./components/Routes/RequireOnBoard";

const PING = gql`
  query Ping {
    ping
  }
`;

// const PING_SUB = gql`
//   subscription PingSub {
//     pingSub {
//       content
//     }
//   }
// `;

const App = () => {
  const { auth, setAuth } = useAuth();
  const { setDepartmentFilter } = useDepartmentFilter();
  const { resetLoading } = useContext(LoadingContext);

  const [authToggle, setAuthToggle] = useState(0);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [prevPath, setPrevPath] = useState(() => location.pathname);

  const { loading: pingLoading } = useQuery(PING, {
    onCompleted: (data) => {
      if (_.get(data, "ping")) {
        setAuthToggle((prev) => !prev);
      }
    },
    onError: (err) => {
      console.log("Error: ", err);
    },
  });

  // const { data: pingSubData, loading: pingSubLoading } = useSubscription(PING_SUB);
  // console.log({ pingSub: _.get(pingSubData, "pingSub.content") });

  const handleAuthentication = (accessToken) => {
    localStorage.setItem("accessToken", accessToken);
    setAuth(jwt_decode(accessToken));
  };

  //for admin users, their department filter needs resetting when changing over organizations
  useEffect(() => {
    if (location.pathname !== prevPath) {
      const pathDirectories = location.pathname.split("/");
      const prevPathDirectories = prevPath.split("/");

      if (prevPathDirectories[1] !== pathDirectories[1]) {
        if (auth) {
          setDepartmentFilter({ id: null, sharedPlanId: null });
        }
      }

      // clear one year objectives session keys when navigating away from the page
      if (prevPathDirectories[2] !== pathDirectories[2] && prevPathDirectories[2] === "1-year") {
        var oneYearSessionKeys = [];
        for (let i = 0; i < sessionStorage.length; i++) {
          if (sessionStorage.key(i).startsWith("oneYearObjs.")) {
            oneYearSessionKeys.push(sessionStorage.key(i));
          }
        }

        for (let i = 0; i < oneYearSessionKeys.length; i++) {
          sessionStorage.removeItem(oneYearSessionKeys[i]);
        }
      }

      resetLoading();
      setPrevPath(location.pathname);
    }
  }, [location]);

  useEffect(() => {
    if (!_.isNil(auth)) return;

    setLoading(true);

    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      const decodedToken = jwt_decode(accessToken);

      if (decodedToken.exp * 1000 < Date.now()) {
        localStorage.removeItem("accessToken");
      } else {
        setAuth(decodedToken);
      }
    }

    setLoading(false);
  }, [auth, authToggle]);

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty("--primary-color", theme.palette.primary.main);
    root.style.setProperty("--primary-color-light", theme.palette.primary.light);
    root.style.setProperty("--primary-color-dark", theme.palette.primary.dark);
    root.style.setProperty("--secondary-color", theme.palette.secondary.main);
    root.style.setProperty("--secondary-color-light", theme.palette.secondary.light);
    root.style.setProperty("--secondary-color-dark", theme.palette.secondary.dark);
  }, [theme]);

  if (loading) return <Loading fullscreen size={128} />;
  const authenticated = !_.isNil(auth);
  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <CssBaseline />
          <SnackbarContextProvider>
            <Routes>
              <Route path="/signin" element={authenticated ? <Navigate to="/" /> : <Signin authenticate={handleAuthentication} />} />
              <Route path="/" element={authenticated ? <AuthRedirect /> : <Navigate to="/signin" />} />
              <Route exact path="/verify-email/:token" element={<VerifyEmail />} />
              <Route path="/reset-password/:token" element={<PasswordReset />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/security" element={<Security />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route exact path="/signup" element={<Signup />} />

              <Route element={<RequireAuth roleAccess="vantage facilitator" />}>
                <Route path="/organizations" element={<SelectOrganization />} />
              </Route>
              <Route element={<RequireAuth roleAccess="department employee" />}>
                <Route path="/:org/*" element={<Layout />} />
              </Route>
              <Route element={<RequireOnboard />}>
                <Route exact path="/onboard" element={<OnBoard />} />
              </Route>

              {/* Root fallback */}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </SnackbarContextProvider>
        </StylesProvider>
      </ThemeProvider>
    </QueryParamProvider>
  );
};

export default App;
