import { ApolloClient, ApolloLink, split, InMemoryCache, from, HttpLink } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import gql from "graphql-tag";
import jwt_decode from "jwt-decode";
import _ from "lodash";

import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient, CloseCode } from "graphql-ws";
import { removeTypenameFromVariables } from "@apollo/client/link/remove-typename";

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_SERVER_URI,
  credentials: process.env.REACT_APP_ENV === "LOCAL" ? "include" : "same-origin", // need to use "include" b/c of diff localhost ports for the frontend and backend domains
  includeExtensions: true,
});

// Function to get the remaining expiration time of the current token
const getCurrentTokenExpiresIn = () => {
  const token = localStorage.getItem("accessToken");
  if (!token) return 0;

  try {
    const decodedToken = jwt_decode(token); // Decode the JWT to extract its payload
    const currentTime = Math.floor(Date.now() / 1000); // Get the current time in seconds

    if (decodedToken.exp) {
      const expiresIn = decodedToken.exp - currentTime; // Calculate remaining time
      return expiresIn > 0 ? expiresIn * 1000 : 0; // Return time in milliseconds, or 0 if expired
    }

    return 0;
  } catch (error) {
    console.error("Error decoding token", error);
    return 0;
  }
};

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const accessToken = localStorage.getItem("accessToken");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : "",
    },
  };
});

const removeTypenameLink = removeTypenameFromVariables();

// **ORIG
// const errorLink = onError(({ graphQLErrors, networkError }) => {
//   if (graphQLErrors)
//     graphQLErrors.map(({ message, locations, path }) => console.log(`[GraphQL error]: Message: ${message}`, { locations, path }));

//   if (networkError) console.log(`[Network error]: ${networkError}`);
// });

// Function to generate links for handling unauthenticated errors
const generateRefreshTokenLinkOnUnauthError = ({ refreshTokenPathName, refreshTokenRequestFunc }) => {
  return [
    onError(({ graphQLErrors, operation, networkError, forward }) => {
      if (graphQLErrors) {
        for (let err of graphQLErrors) {
          const { message, locations, path = [] } = err;
          console.log(`[GraphQL error]: Message: ${message}`, { locations, path });

          if (path.includes(refreshTokenPathName)) break;

          switch (err.extensions.code) {
            // Apollo Server sets code to UNAUTHENTICATED
            // when an AuthenticationError is thrown in a resolver
            case "UNAUTHENTICATED":
              // Modify the operation context with a new token
              const { getContext, setContext } = operation;
              const context = getContext();

              setContext({
                ...context,
                headers: {
                  ...context?.headers,
                  _needsRefresh: true,
                },
              });
              // Retry the request, returning the new observable
              return forward(operation);
            default:
          }
        }
      }

      if (networkError) console.error(`[Network error]: ${networkError}`);
    }),
    setContext(async (_, previousContext) => {
      if (previousContext.headers && previousContext.headers._needsRefresh) {
        await refreshTokenRequestFunc();
      }

      return previousContext;
    }),
  ];
};

// Function to request a new access token using the refresh token
const refreshTokenReq = async () => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation RefreshAuth {
          refreshAuth {
            accessToken
          }
        }
      `,
    });

    const { accessToken } = response.data?.refreshAuth || {};
    if (accessToken) localStorage.setItem("accessToken", accessToken);
  } catch (err) {
    console.log("Error: ", err);
  }
};

let shouldRefreshToken = false,
  // the socket close timeout due to token expiry
  tokenExpiryTimeout = null;

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_SUBSCRIPTION_URI,
    // connectionParams: { authToken: localStorage.getItem("accessToken") },
    // on: {
    //   connected: () => console.log("WebSocket connected"),
    //   closed: () => console.log("WebSocket disconnected"),
    //   error: (err) => console.log("WebSocket error", err),
    // },
    connectionParams: async () => {
      if (shouldRefreshToken) {
        // console.log("WS REFRESH TOKEN");

        // refresh the token because it is no longer valid
        await refreshTokenReq();
        // and reset the flag to avoid refreshing too many times
        shouldRefreshToken = false;
      }
      return { authToken: localStorage.getItem("accessToken") };
    },
    on: {
      connected: (socket) => {
        // clear timeout on every connect for debouncing the expiry
        clearTimeout(tokenExpiryTimeout);

        // set a token expiry timeout for closing the socket
        // with an `4403: Forbidden` close event indicating
        // that the token expired. the `closed` event listner below
        // will set the token refresh flag to true
        tokenExpiryTimeout = setTimeout(() => {
          if (socket.readyState === WebSocket.OPEN) socket.close(CloseCode.Forbidden, "Forbidden");
        }, getCurrentTokenExpiresIn());
      },
      closed: (event) => {
        // if closed with the `4403: Forbidden` close event
        // the client or the server is communicating that the token
        // is no longer valid and should be therefore refreshed
        if (event.code === CloseCode.Forbidden) {
          // console.log("WS CLOSED EVENT (FORBIDDEN)");

          shouldRefreshToken = true;
        }
      },
    },
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  from([wsLink]),
  from([
    ...generateRefreshTokenLinkOnUnauthError({
      refreshTokenPathName: "refreshAuth",
      refreshTokenRequestFunc: refreshTokenReq,
    }),
    authLink,
    httpLink,
  ])
);

const link = from([removeTypenameLink, splitLink]);

// Connect to the Apollo Server back end
const client = new ApolloClient({
  connectToDevTools: true, //uncomment when you want to work with apollo dev tools
  link,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          moreTodos: {
            keyArgs: ["organization", "sharedPlanId", "category", "searchTerm", "oneYearCorpPlan"],
            merge(existing = { todos: [] }, incoming) {
              return {
                todos: [...existing.todos, ...incoming.todos],
                nextCursor: incoming.nextCursor,
              };
            },
          },
        },
      },
    },
  }),
  credentials: "include",
});

export default client;
