import { Chip, TableBody } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
// import Rock from "../Rocks/Rock";
// import { addToDate, quarterDates } from "../../utils/dates";
// import { isAuthed } from "../../utils/authorization";
import { DialogContext } from "../../context/dialogContext";
import _ from "lodash";
import { SnackbarContext } from "../../context/snackbarContext";
import { useQuery } from "@apollo/client";
import { GET_ROCKS } from "../../utils/query";
import { FetchContext } from "../../context/fetchContext";
import { useAuth } from "../../context/authContext";
import { useDepartmentFilter } from "../../context/departmentFilterContext";
import styles from "./Objectives.module.scss";

const RockTable = ({ organization, fiscalYear, planId }) => {
  const { dialog, setDialog } = useContext(DialogContext);
  const { auth } = useAuth();
  const { departmentFilter } = useDepartmentFilter();
  const { snack } = useContext(SnackbarContext);
  const { fetch } = useContext(FetchContext);

  const variables = {
    organization: organization,
    sharedPlanId: departmentFilter.sharedPlanId,
    objective: null,
  };
  const { data, refetch } = useQuery(GET_ROCKS, {
    variables,
  });

  useEffect(() => {
    refetch();
  }, [fetch]);

  const rocks = _.get(data, "rocks") || [];

  const handleEditDialog = (open, rock) => () => {
    setDialog({ ...dialog, addRockDialog: { open: true, rock, planId } });
  };

  // const rockQuarterDict = rocks.reduce(
  //   (accum, currRock) => ({ ...accum, [currRock.id]: quarterDates(addToDate(fiscalYear, { days: 1 }), currRock.index)[1] }),
  //   {}
  // );

  // const handleAddSCDialog = (id) => () => {
  //   setDialog({ ...dialog, addSuccessCriteriaDialog: { open: true, rock: id } });
  // };

  // const handleAddIssueDialog =
  //   (referenceId = null, referenceModel = null, value = null, user = null) =>
  //   () => {
  //     setDialog({
  //       ...dialog,
  //       addTodoDialog: {
  //         open: true,
  //         category: "issue",
  //         referenceId,
  //         referenceModel,
  //         value,
  //         user,
  //         planId,
  //       },
  //     });
  //   };

  // const handleDuplicate =
  //   ({ objectiveId, index, value, id, successCriterias }) =>
  //   () => {
  //     setDialog({
  //       ...dialog,
  //       addRockDialog: {
  //         open: true,
  //         objective: objectiveId,
  //         quarter: index,
  //         value,
  //         users: [],
  //         duplicate: id,
  //         planId,
  //         successCriterias: successCriterias.map((sc) => _.omit(sc, ["id"])),
  //         variables,
  //       },
  //     });
  //   };

  // const ensureDefault = (fnc, def) => (fnc === null ? def : fnc);

  return (
    <>
      <div className={styles.parkingLot}>
        {rocks.map((rock, i) => {
          return <Chip key={i} label={rock.value} onClick={handleEditDialog(true, rock)} variant="outlined" />;
        })}
      </div>
      {/* <TableBody>
        {rocks.map((rock, i) => (
          <Rock
            key={i}
            rock={rock}
            quarterDate={rockQuarterDict[rock.id]}
            handleAddSCDialog={handleAddSCDialog}
            handleAddIssueDialog={handleAddIssueDialog}
            handleDuplicate={handleDuplicate}
            showAllSc={false}
            canEdit={isAuthed(auth, "department facilitator") && !ensureDefault(_.get(rock, "archived"), false)}
            handleEditDialog={handleEditDialog}
            planId={null}
          />
        ))}
      </TableBody> */}
    </>
  );
};

export default RockTable;
