import React from "react";
import _ from "lodash";
import { Button, ButtonGroup, MenuItem, TextField, Typography, useTheme } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

const NewSelectDepartment = ({
  name,
  helperText,
  formik,
  plans = [],
  showAll = false,
  multiple = false,
  disabled = false,
  buttonGroup = false,
  handleSelect,
}) => {
  const theme = useTheme();

  const { values, errors, touched, getFieldProps, setFieldValue, setFieldTouched } = formik;

  const isTouched = _.get(touched, name);
  const error = _.get(errors, name);

  const hasError = Boolean(isTouched && error);
  const helperTxt = hasError ? error : helperText;

  return buttonGroup ? (
    <div style={{ margin: `${theme.spacing(2)}px 0`, textAlign: "center" }}>
      <ToggleButtonGroup
        exclusive
        value={values[name]}
        onChange={(e, value) => {
          setFieldTouched(name);

          if (_.isNil(handleSelect)) {
            setFieldValue(name, value);
          } else {
            handleSelect(value);
          }
        }}
        style={{
          flexWrap: "wrap",
          gap: theme.spacing(1),
          justifyContent: "center",
        }}
      >
        {showAll && <ToggleButton value={null}>No Department</ToggleButton>}
        {!_.isEmpty(plans) &&
          plans.map(({ id, departmentName }) => (
            <ToggleButton key={id} value={id} style={{ border: `1px solid ${theme.palette.grey["A100"]}` }}>
              {departmentName}
            </ToggleButton>
          ))}
      </ToggleButtonGroup>
      <Typography
        variant="caption"
        style={{
          display: "block",
          color: hasError ? theme.palette.error.main : "unset",
          textAlign: "center",
        }}
      >
        {helperTxt}
      </Typography>
    </div>
  ) : (
    <TextField
      select
      fullWidth
      label="Department"
      variant="outlined"
      margin="normal"
      {...getFieldProps(name)}
      {...(handleSelect ? { onChange: (e) => handleSelect(e.target.value) } : {})}
      SelectProps={{
        multiple,
      }}
      InputLabelProps={{
        shrink: !_.isEmpty(values[name]),
      }}
      error={hasError}
      helperText={helperTxt}
      disabled={disabled}
    >
      {showAll && <MenuItem value={null}>No Department</MenuItem>}
      {!_.isEmpty(plans) &&
        plans.map(({ id, departmentName }) => (
          <MenuItem key={id} value={id}>
            {departmentName}
          </MenuItem>
        ))}
    </TextField>
  );
};

export default NewSelectDepartment;
