import React, { useEffect, useState, useContext, useCallback, useMemo } from "react";
import { useAuth } from "../../context/authContext";
import styles from "./AddEditMeetingDialog.module.scss";
import _ from "lodash";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import useForm from "../../hooks/useForm";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";

import {
  styled,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  Stepper,
  Step,
  StepLabel,
  Chip,
  Avatar,
  Checkbox,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import { TimePicker, DatePicker } from "@material-ui/pickers";
import Loading from "../Loading/Loading";
import { getDay, mergeDateAndTime } from "../../utils/dates";

import SelectUsers from "../SelectUsers/SelectUsers";
import SelectDepartment from "../SelectDepartment/SelectDepartment";
import SelectTimezone from "../SelectTimezone/SelectTimezone";
import MeetingStepsList from "./MeetingStepsList";
import { utcToZonedTime } from "date-fns-tz";
import { MEETING_FIELDS } from "../../utils/fragments";
import { getMeetingStepsFromType, recursiveMergeCustomizer } from "../../utils/misc";
import NewSelectDepartment from "../SelectDepartment/NewSelectDepartment";

const StyledChip = styled(Chip)(({ theme }) => ({
  color: "white",
  backgroundColor: theme.palette.primary.main,
  "& .MuiChip-deleteIcon": {
    color: "white",
  },
}));

const DIALOG_STEPS = ["General", "Type", "Attendees"];
const USER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

const AddEditMeetingDialog = ({ dialog, setDialog, requestFetch, fetch, params, snack }) => {
  const fs = useMediaQuery("(max-width: 600px)");
  const { auth } = useAuth();

  const meetingDialog = _.get(dialog, "addMeetingDialog", {});
  const { open, meeting, planId, view = false } = meetingDialog;

  const isCreate = _.isNil(meeting);

  const [activeStep, setActiveStep] = useState(0);

  const [createMeeting, { loading: createLoading }] = useMutation(CREATE_MEETING, {
    // update(cache, { data: { createMeeting } }) {
    //   cache.modify({
    //     fields: {
    //       meetings(existingMeetings = []) {
    //         const newMeetingRef = cache.writeFragment({
    //           data: createMeeting,
    //           fragment: MEETING_FIELDS,
    //         });
    //         return [...existingMeetings, newMeetingRef];
    //       },
    //     },
    //   });
    // },
  });

  const [updateMeeting, { loading: updateLoading }] = useMutation(UPDATE_MEETING, {
    refetchQueries: ["Meetings_GetMeetings"], // even when returning id of updated meeting, meetings page does not see changes unless refetch is made on query
  });

  const { data, refetch } = useQuery(GET_USERS_AND_DEPARTMENTS, {
    variables: { organization: params.org, oneYearCorpPlan: planId || null },
  });

  const usersById = useMemo(() => {
    if (!data) return null;

    const users = _.get(data, "users", []);
    return _.keyBy(users, "id");
  }, [data]);

  const handleClose = () => {
    setDialog({ ...dialog, addMeetingDialog: { open: false } });
  };

  // FORMIK START
  const schema = Yup.object().shape({
    title: Yup.string().required("Name is required"),
    startDate: Yup.date(), // should have pre-selected default
    startTime: Yup.date(), // should have pre-selected default
    timezone: Yup.string(), // should have pre-selected default
    frequency: Yup.number(), // should have pre-selected default
    type: Yup.string(), // should have pre-selected default
    steps: Yup.array(),
    plan: Yup.string().nullable(),
    users: Yup.array().of(Yup.string().required("User is required")),
  });

  const stepFields = [
    ["title", "frequency", "startDate", "startTime", "timezone"],
    ["type", "steps"],
    ["plan", "users"],
  ];

  const getInitialValues = (meeting) => {
    const newDate = getDefaultMeetingStartDate();
    const type = "hem";

    let initVals = {
      title: "",
      startDate: newDate,
      startTime: newDate,
      timezone: USER_TIMEZONE,
      frequency: 0,
      type,
      steps: getMeetingStepsFromType(type),
      plan: null,
      users: [],
    };

    if (!_.isNil(meeting)) {
      initVals = _.mergeWith({}, initVals, meeting, (objVal, srcVal) => recursiveMergeCustomizer(srcVal));

      const { startTime, timezone, steps } = initVals;

      const startDateTime = new Date(parseInt(startTime));
      const zonedTime = utcToZonedTime(startDateTime, timezone);

      const sanitizedSteps = steps.map((step) => _.omit(step, "__typename"));

      initVals = { ...initVals, startTime: zonedTime, startDate: zonedTime, steps: sanitizedSteps };
    }

    return initVals;
  };

  const formik = useFormik({
    initialValues: getInitialValues(meeting),
    validationSchema: schema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const { title, frequency, startDate, startTime, timezone, type, steps, plan, users } = values;
      const mergedDateTime = mergeDateAndTime(startDate, startTime, timezone);

      try {
        let res;
        if (isCreate) {
          res = await createMeeting({
            variables: {
              organization: params.org,
              owner: auth.id,
              title,
              frequency,
              startTime: mergedDateTime,
              timezone,
              type,
              steps,
              users,
              plan,
            },
          });
        } else {
          res = await updateMeeting({
            variables: {
              id: meeting.id,
              title,
              frequency,
              startTime: mergedDateTime,
              timezone,
              type,
              steps,
              users,
              plan,
            },
          });
        }

        if (_.get(res, `data.${isCreate ? "createMeeting" : "updateMeeting"}`, false)) {
          snack(`${isCreate ? "Created" : "Updated"} "${title}" meeting`);
          handleClose();
          requestFetch();
        }
        setSubmitting(false);
      } catch (error) {
        snack(`Failed to ${isCreate ? "create" : "update"} meeting`, "error");
      }
    },
    enableReinitialize: true,
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    setValues,
    setTouched,
    setFieldTouched,
    resetForm,
    validateForm,
    dirty,
  } = formik;

  const handleNext = async () => {
    const currStepFields = stepFields[activeStep];

    const touchedFields = {};
    currStepFields.forEach((field) => {
      touchedFields[field] = true;
    });
    setTouched(touchedFields, true);

    // Validate all fields for the current stage
    const errors = await validateForm();

    // Check if there are errors for fields in the current stage
    const hasStageErrors = currStepFields.some((field) => errors[field]);

    if (!hasStageErrors) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (open) {
      refetch();
    } else {
      setActiveStep(0);
      resetForm();
    }
  }, [fetch, open, refetch, resetForm]);

  const getAvatar = (user) => {
    const { name, profilePicture } = user;
    return (
      <Avatar src={profilePicture} className={styles.avatar}>
        {_.get(name, ["first", "0"])}
        {_.get(name, ["last", "0"])}
      </Avatar>
    );
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <TextField
              autoFocus
              fullWidth
              label="Name"
              variant="outlined"
              margin="normal"
              {...getFieldProps("title")}
              error={Boolean(touched.title && errors.title)}
              helperText={touched.title && errors.title}
              disabled={view}
            />
            <TextField
              fullWidth
              select
              label="Frequency"
              variant="outlined"
              margin="normal"
              {...getFieldProps("frequency")}
              error={Boolean(touched.frequency && errors.frequency)}
              helperText={touched.frequency && errors.frequency}
              disabled={view}
            >
              <MenuItem value={0}>One Time</MenuItem>
              <MenuItem value={1}>Weekly</MenuItem>
              <MenuItem value={2}>Bi-weekly</MenuItem>
              <MenuItem value={4}>Monthly</MenuItem>
              <MenuItem value={13}>Quarterly</MenuItem>
              <MenuItem value={52}>Annually</MenuItem>
            </TextField>

            <DatePicker
              autoOk
              // clearable
              value={values.startDate}
              onChange={(date) => setFieldValue("startDate", date)}
              fullWidth
              inputVariant="outlined"
              format="dd/MM/yyyy"
              margin="normal"
              label="Start Date"
              disabled={view}
            />
            <TimePicker
              minutesStep={5}
              fullWidth
              variant="inline"
              inputVariant="outlined"
              label="Start Time"
              margin="normal"
              value={values.startTime || ""}
              onChange={(date) => setFieldValue("startTime", date)}
              disabled={view}
            />
            <SelectTimezone
              value={values.timezone}
              handleChange={({ value }) => setFieldValue("timezone", value)}
              additionalTimezones={[USER_TIMEZONE]}
              disabled={view}
            />
          </>
        );
      case 1:
        return (
          <>
            <TextField
              fullWidth
              select
              label="Type"
              variant="outlined"
              margin="normal"
              {...getFieldProps("type")}
              error={Boolean(touched.type && errors.type)}
              helperText={touched.type && errors.type}
              disabled={view}
            >
              <MenuItem value="hem">HEM</MenuItem>
              {/* <MenuItem value="quarterly">Quarterly</MenuItem>
                <MenuItem value="annual">Annual</MenuItem>
                <MenuItem value="foundation">Foundation Building</MenuItem>
                <MenuItem value="core">Core Building</MenuItem>
                <MenuItem value="leader">Leader Building</MenuItem> */}
              <MenuItem value="custom">Custom</MenuItem>
            </TextField>
            <MeetingStepsList type={values.type} steps={values.steps} onChange={(value) => setFieldValue("steps", value)} disabled={view} />
          </>
        );
      case 2:
        return (
          <>
            <NewSelectDepartment
              showAll
              name="plan"
              plans={_.get(data, "plans")}
              formik={formik}
              handleSelect={(value) => setValues({ ...values, plan: value, users: [] })}
              disabled={view}
            />
            <Autocomplete
              fullWidth
              multiple
              disableCloseOnSelect
              filterSelectedOptions
              name="users"
              onChange={(e, users) => setFieldValue("users", users)}
              value={values.users}
              style={{ marginTop: 16 }}
              options={_.get(data, "users", []).map((user) => user.id)}
              disabled={view}
              filterOptions={(userIds) =>
                userIds.filter((userId) => {
                  const user = usersById[userId];
                  return _.isEmpty(values.plan) || _.some(user.plan, ["id", values.plan]);
                })
              }
              getOptionLabel={(userId) => {
                const user = usersById[userId];
                return `${user.name.first} ${user.name.last}`;
              }}
              renderOption={(userId, { selected }) => {
                const user = usersById[userId];
                return (
                  <>
                    <Checkbox icon={getAvatar(user)} checkedIcon={getAvatar(user)} checked={selected} />
                    {user.name.first} {user.name.last}
                  </>
                );
              }}
              renderInput={(params) => <TextField {...params} label="Attendees" variant="outlined" />}
              renderTags={(userIds, getTagProps) =>
                userIds.map((userId, index) => {
                  const user = usersById[userId];
                  return (
                    <StyledChip
                      variant="outlined"
                      label={`${user.name.first} ${user.name.last}`}
                      {...getTagProps({ index })}
                      avatar={getAvatar(user)}
                    />
                  );
                })
              }
            />
          </>
        );
      default:
        return;
    }
  };

  const isLastStep = activeStep === DIALOG_STEPS.length - 1;

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      fullWidth
      fullScreen={fs}
    >
      <DialogTitle>
        <div className={styles.title}>
          {view ? "View" : isCreate ? "Create" : "Edit"} Meeting
          <div>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep} className={styles.stepper}>
          {DIALOG_STEPS.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <FormikProvider formik={formik}>{getStepContent(activeStep)}</FormikProvider>
      </DialogContent>
      <DialogActions>
        <div style={{ marginRight: "auto", display: "flex", gap: 8 }}>
          <Button disabled={activeStep === 0} onClick={handleBack}>
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={isLastStep ? handleSubmit : handleNext}
            disabled={isSubmitting || (isLastStep && (!dirty || view))}
          >
            {isLastStep ? (isCreate ? "Create" : "Save") : "Next"}
          </Button>
        </div>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditMeetingDialog;

const CREATE_MEETING = gql`
  ${MEETING_FIELDS}
  mutation AddMeetingDialog_CreateMeeting(
    $organization: ID!
    $owner: ID!
    $title: String!
    $frequency: Int!
    $startTime: String!
    $timezone: String!
    $type: String!
    $steps: [InputStep!]
    $users: [ID!]
    $plan: ID
  ) {
    createMeeting(
      organization: $organization
      owner: $owner
      title: $title
      frequency: $frequency
      startTime: $startTime
      timezone: $timezone
      type: $type
      steps: $steps
      users: $users
      plan: $plan
    ) {
      ...MeetingFields
    }
  }
`;

const UPDATE_MEETING = gql`
  ${MEETING_FIELDS}
  mutation AddMeetingDialog_UpdateMeeting(
    $id: ID!
    $title: String!
    $frequency: Int!
    $startTime: String!
    $timezone: String!
    $type: String!
    $steps: [InputStep!]
    $users: [ID!]
    $plan: ID
  ) {
    updateMeeting(
      id: $id
      title: $title
      frequency: $frequency
      startTime: $startTime
      timezone: $timezone
      type: $type
      steps: $steps
      users: $users
      plan: $plan
    ) {
      ...MeetingFields
    }
  }
`;

const GET_USERS_AND_DEPARTMENTS = gql`
  query AddMeetingDialog_GetUsersDepts($organization: ID!, $oneYearCorpPlan: ID) {
    users(organization: $organization) {
      name {
        first
        last
      }
      profilePicture
      id
      plan {
        id
        departmentName
        sharedPlanId
      }
    }

    plans(organization: $organization, oneYearCorpPlan: $oneYearCorpPlan, category: "1 year", closed: false) {
      id
      departmentName
      sharedPlanId
    }
  }
`;

const getDefaultMeetingStartDate = () => {
  let currentDate = new Date();
  let minutes = currentDate.getMinutes();
  let newMinutes = minutes + 5 - (minutes % 5);
  currentDate.setMinutes(newMinutes);
  currentDate.setSeconds(0);
  currentDate.setMilliseconds(0);
  return currentDate;
};
