import React, { useEffect, useState, useMemo, useContext } from "react";
import styles from "./Dashboard.module.scss";
import _ from "lodash";
import gql from "graphql-tag";
import { useAuth } from "../../context/authContext";
import { useDepartmentFilter } from "../../context/departmentFilterContext";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { GET_MEETINGS, GET_SUCCESS_CRITERIAS } from "../../utils/query";
import { MEETINGS_SUBSCRIPTION } from "../../utils/subscriptions";
import { startOfMonth, endOfMonth, formatAs, getYear, setYear } from "../../utils/dates";
import { DialogContext } from "../../context/dialogContext";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { format } from "date-fns";

import Icon from "@mdi/react";
import { mdiGoogleClassroom, mdiRhombusSplitOutline } from "@mdi/js";
import { Button, IconButton, Menu, MenuItem, Popover, Tooltip, Typography, useTheme } from "@material-ui/core";

import NewCalendar from "../../components/Calendar/NewCalendar";
import { Pageview, MeetingRoom } from "@material-ui/icons";
import { getFiscalQuarter } from "../../utils/misc";

const USER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

const Calendar = ({ org, filterUser }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { dialog, setDialog } = useContext(DialogContext);

  const { auth } = useAuth();
  const { departmentFilter } = useDepartmentFilter();

  const [selectedDate, setSelectedDate] = useState(startOfMonth(new Date()));

  const [anchorEl, setAnchorEl] = useState(null);
  const [eventsContent, setEventsContent] = useState(null);
  const [menuOptions, setMenuOptions] = useState(null);

  const { data: planYearsData } = useQuery(GET_PLAN_YEARS, {
    variables: {
      organization: org.id,
    },
  });

  const { data: meetingsData, subscribeToMore } = useQuery(GET_MEETINGS, {
    variables: {
      organization: org.id,
      sharedPlanId: departmentFilter.sharedPlanId,
      meetingUser: _.get(filterUser, "id", null),
      startDate: startOfMonth(selectedDate),
      endDate: endOfMonth(selectedDate),
    },
  });

  const { data: successCriteriaData } = useQuery(GET_SUCCESS_CRITERIAS, {
    variables: {
      organization: org.id,
      sharedPlanId: departmentFilter.sharedPlanId,
      users: [...(filterUser ? [filterUser.id] : [])],
      startDate: startOfMonth(selectedDate),
      endDate: endOfMonth(selectedDate),
    },
  });

  const eventsByDate = useMemo(() => {
    const dict = {};

    if (!meetingsData || !successCriteriaData) return dict;

    const meetings = _.get(meetingsData, "meetings", []);
    for (const meeting of meetings) {
      const startTime = _.get(meeting, "startTime");
      const meetingWithType = { ...meeting, eventType: "meeting" };

      if (!_.isNil(startTime)) {
        const dateKey = formatAs(startTime, "yyyy-MM-dd");

        if (_.has(dict, dateKey)) {
          dict[dateKey].push(meetingWithType);
        } else {
          dict[dateKey] = [meetingWithType];
        }
      }
    }

    const successCriterias = _.get(successCriteriaData, "successCriterias", []);

    for (const sc of successCriterias) {
      const targetDate = _.get(sc, "targetDate");

      if (!_.isNil(targetDate)) {
        const dateKey = formatAs(targetDate, "yyyy-MM-dd");
        const scWithType = { ...sc, eventType: "successCriteria" };

        if (_.has(dict, dateKey)) {
          dict[dateKey].push(scWithType);
        } else {
          dict[dateKey] = [scWithType];
        }
      }
    }

    return dict;
  }, [meetingsData, successCriteriaData]);

  const quarterEnds = useMemo(() => {
    const quarterEndsMap = new Map();
    const currFiscalYear = org.fiscalYear;

    if (!currFiscalYear || !planYearsData) return quarterEndsMap;

    const oneYearCorpPlans = _.get(planYearsData, "plans", []);

    for (const corpPlan of oneYearCorpPlans) {
      const planYear = getYear(_.get(corpPlan, "year"));
      const fiscalYear = setYear(currFiscalYear, planYear);

      for (const quarter of [1, 2, 3, 4]) {
        const [start, end] = getFiscalQuarter(fiscalYear, quarter);
        quarterEndsMap.set(formatAs(end, "yyyy-MM-dd"), quarter);
      }
    }

    return quarterEndsMap;
  }, [org, planYearsData]);

  const handleOpenPopoverMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClosePopoverMenu = () => {
    setAnchorEl(null);
    // setEventsContent(null);
    // setMenuOptions(null);
  };

  const getUTCStr = (date) => {
    const utcDate = zonedTimeToUtc(date, USER_TIMEZONE);
    return formatAs(utcDate, "yyyy-MM-dd");
  };

  const handleOpenMeetingDialog = (meeting) => {
    setDialog({
      ...dialog,
      addMeetingDialog: {
        open: true,
        meeting,
        view: true,
        // planId: _.get(corpForSelectedYear, "id") //**TODO: need to handle this query variable when changing years
      },
    });
  };

  const handleClickMeeting = (meetingId) => {
    navigate(`/${org.id}/meeting/${meetingId}`);
  };

  const handeActiveStartDateChange = ({ action, activeStartDate, value, view }) => {
    // console.log("handeActiveStartDateChange LOG:", { action, activeStartDate, value, view });

    if (["drilLDown", "next", "next2", "prev", "prev2"]) {
      setSelectedDate(activeStartDate);
    }
  };

  // const handleClickTile = (value, event) => {
  //   const utcStr = getUTCStr(value);
  //   setSelectedDate(value);
  // };

  const getPlanPill = (plan) => {
    if (_.isEmpty(plan)) return null;

    const planBackupStr = _.get(plan, "departmentName", "").slice(0, 4);
    const planStr = _.toUpper(_.get(plan, "shortName") || planBackupStr);
    const planColor = _.get(plan, "color") || "#000000";

    return (
      <>
        <Typography
          display="inline"
          variant="caption"
          className={styles.calendarItemDept}
          style={{ backgroundColor: planColor, color: theme.palette.getContrastText(planColor), marginTop: -2 }}
        >
          {planStr}
        </Typography>{" "}
      </>
    );
  };

  const getEventJsx = ({ isPopover = false, type, event }) => {
    let jsx;
    if (type === "meeting") {
      const { startTime, title, plan } = event;
      const timeStr = formatAs(startTime, "h:mm a");

      jsx = (
        <>
          <Icon path={mdiGoogleClassroom} size={0.85} color="black" style={{ minWidth: "max-content" }} />
          <div className={isPopover ? styles.calendarPopoverItemText : styles.calendarEventsItemText}>
            {getPlanPill(plan)}
            <strong>{timeStr}</strong> | {title}
          </div>
        </>
      );
    } else if (type === "successCriteria") {
      const { rock, value } = event;
      const plan = _.get(rock, "plan");

      jsx = (
        <>
          <Icon path={mdiRhombusSplitOutline} size={0.85} color="black" style={{ minWidth: "max-content" }} />
          <div className={isPopover ? styles.calendarPopoverItemText : styles.calendarEventsItemText}>
            {getPlanPill(plan)}
            {value}
          </div>
        </>
      );
    }

    return jsx;
  };

  const tileContent = ({ date, view }) => {
    let ret;
    if (view === "month") {
      const utcStr = getUTCStr(date);
      const events = _.get(eventsByDate, utcStr, []);

      const meetings = _.filter(events, ["eventType", "meeting"]);
      const sortedMeetings = _.sortBy(meetings, (meeting) => parseInt(meeting.startTime));

      let meetingEvents = [];
      for (const meeting of sortedMeetings) {
        meetingEvents.push({
          event: meeting,
          handleJoin: () => {
            handleClickMeeting(meeting.id);
          },
          handleView: () => {
            handleOpenMeetingDialog(meeting);
          },
        });
      }

      const scs = _.filter(events, ["eventType", "successCriteria"]);
      let scEvents = [];
      for (const sc of scs) {
        scEvents.push({
          event: sc,
        });
      }

      const eventsArr = [...meetingEvents, ...scEvents];
      const isQuarterEnd = quarterEnds.has(utcStr);

      ret = (
        <div
          className={styles.calendarEvents}
          style={{
            gap: theme.spacing(0.5),
          }}
        >
          <span style={{ fontWeight: "bold" }}>{isQuarterEnd && `Q${quarterEnds.get(utcStr)} END`}</span>
          {eventsArr.length > 2 ? (
            <div
              className={styles.calendarEventsItem}
              style={{
                gap: theme.spacing(0.5),
                padding: theme.spacing(0.5),
                fontSize: theme.typography.button.fontSize,
                borderColor: theme.palette.divider,
                justifyContent: "center",
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleOpenPopoverMenu(e);

                setEventsContent(
                  eventsArr.map(({ event, handleJoin, handleView }) => {
                    const { eventType } = event;
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: theme.spacing(1),
                          marginTop: theme.spacing(0.5),
                        }}
                      >
                        <div className={styles.calendarPopoverItem} style={{ gap: theme.spacing(0.5) }}>
                          {getEventJsx({ type: eventType, event, isPopover: true })}
                        </div>
                        {eventType === "meeting" && (
                          <div style={{ display: "flex" }}>
                            <IconButton style={{ padding: 0 }} title="View">
                              <Pageview onClick={handleView} />
                            </IconButton>
                            <IconButton style={{ padding: 0 }} title="Join">
                              <MeetingRoom onClick={handleJoin} />
                            </IconButton>
                          </div>
                        )}
                      </div>
                    );
                  })
                );
              }}
            >
              <strong>{eventsArr.length} Events</strong>
            </div>
          ) : (
            eventsArr.map(({ event, handleJoin, handleView }) => {
              const { eventType } = event;

              const tooltipKey = { meeting: "title", successCriteria: "value" }[eventType];
              const tooltip = _.get(event, tooltipKey);

              const isMeeting = eventType === "meeting";

              return (
                <Tooltip title={tooltip} placement="left">
                  <div
                    className={styles.calendarEventsItem}
                    onClick={(e) => {
                      e.stopPropagation();

                      if (isMeeting) {
                        handleOpenPopoverMenu(e);
                        setMenuOptions(
                          <>
                            <MenuItem key="view" onClick={handleView}>
                              View
                            </MenuItem>
                            <MenuItem key="join" onClick={handleJoin}>
                              Join
                            </MenuItem>
                          </>
                        );
                      }
                    }}
                    style={{
                      gap: theme.spacing(0.5),
                      padding: theme.spacing(0.5),
                      fontSize: theme.typography.button.fontSize,
                      borderColor: theme.palette.divider,
                      cursor: isMeeting ? "pointer" : "default",
                    }}
                  >
                    {getEventJsx({ type: eventType, event })}
                  </div>
                </Tooltip>
              );
            })
          )}
        </div>
      );
    }

    return ret;
  };

  useEffect(
    () =>
      subscribeToMore({
        document: MEETINGS_SUBSCRIPTION,
        variables: { meetingUser: auth.id },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const meeting = subscriptionData.data.meetingPayload.meetingMutated;
          const action = subscriptionData.data.meetingPayload.action;
          let newMeetings;
          switch (action) {
            case "update":
              newMeetings = prev.meetings.map((t) => (t.id === meeting.id ? meeting : t));
              break;
            case "delete":
              newMeetings = prev.meetings.filter((t) => t.id !== meeting.id);
              break;
            case "create":
              newMeetings = [...prev.meetings, meeting];
              break;
            default:
              break;
          }

          return Object.assign({}, prev, {
            meetings: newMeetings,
          });
        },
      }),
    [auth.id, subscribeToMore]
  );

  useEffect(() => {
    if (_.isNil(anchorEl)) {
      setEventsContent(null);
      setMenuOptions(null);
    }
  }, [anchorEl]);

  return (
    <>
      <div style={{ height: "100%", padding: theme.spacing(3), paddingTop: theme.spacing(2) }}>
        <NewCalendar
          value={selectedDate}
          tileContent={tileContent}
          // onChange={handleClickTile}
          onActiveStartDateChange={handeActiveStartDateChange}
          minDetail="decade"
        />
      </div>
      <Popover
        open={Boolean(anchorEl) && !_.isNil(eventsContent)}
        anchorEl={anchorEl}
        onClose={handleClosePopoverMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <div className={styles.calendarPopover} style={{ padding: theme.spacing(1) }}>
          {eventsContent}
        </div>
      </Popover>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={Boolean(anchorEl) && !_.isNil(menuOptions)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        // onClick={handleClosePopoverMenu}
        onClose={handleClosePopoverMenu}
      >
        {menuOptions}
      </Menu>
    </>
  );
};

export default Calendar;

const GET_PLAN_YEARS = gql`
  query AddMeetingDialog_GetUsersDepts($organization: ID!) {
    plans(organization: $organization, departmentName: "Corporate", category: "1 year") {
      year
    }
  }
`;
