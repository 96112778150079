import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { Droppable, Draggable } from "react-beautiful-dnd";
import styles from "./Rock.module.scss";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { isAuthed } from "../../utils/authorization";
import { isDate, startOfToday } from "date-fns";

import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import { DialogContext } from "../../context/dialogContext";
import { useAuth } from "../../context/authContext";

import Icon from "@mdi/react";
import { mdiCheckboxBlankCircle, mdiDecagram, mdiEyeOffOutline, mdiDrag } from "@mdi/js";
import { green, red } from "@material-ui/core/colors";
import { List, ListItem, ListItemText, ListItemIcon, MenuItem, Collapse, Tooltip, useTheme } from "@material-ui/core";

import Menu from "../Menu/Menu";
import NotesButton from "../Notes/NotesButton";
import UserAvatars from "../UserAvatars/UserAvatars";
import ConfirmDeletionDialog from "../ConfirmDeletionDialog/ConfirmDeletionDialog";
import useConfirmDelete from "../../hooks/useConfirmDelete";
import PlanPill from "../PlanPill/PlanPill";
import { formatAs, before } from "../../utils/dates";

// Rock Widget
const Rock = ({
  isDragging,
  objectiveId,
  canEdit,
  rock,
  rockPath,
  rockScStrategy,
  planId,
  spid,
  provided,
  nextYearPlanCreated,
  isFuturePlan,
  isCurrentPlan,
}) => {
  const { id, value, users, index, successCriterias, status, kanbanStatus, hide, plan } = rock;

  const theme = useTheme();

  const { requestFetch } = useContext(FetchContext);
  const { auth } = useAuth();
  const { snack } = useContext(SnackbarContext);
  const { dialog, setDialog } = useContext(DialogContext);

  const [deleteRock] = useMutation(DELETE_ROCK, {
    update(cache, { data: { deleteRock } }) {
      const { deletedRock, deletedSuccessCriterias } = deleteRock;
      const deletedRockId = cache.identify(deletedRock);
      const deletedScIds = deletedSuccessCriterias.map((sc) => cache.identify(sc));
      cache.modify({
        fields: {
          rocks: (existingRocks) => {
            return existingRocks.filter((rockRef) => {
              const rockId = cache.identify(rockRef);
              return rockId !== deletedRockId;
            });
          },
          successCriterias: (existingScs) => {
            return existingScs.filter((scRef) => {
              const scId = cache.identify(scRef);
              return !deletedScIds.includes(scId);
            });
          },
        },
      });
    },
  });

  const [updateRockStatus] = useMutation(UPDATE_ROCK_STATUS);
  const [updateRockVisibility] = useMutation(UPDATE_ROCK_VISIBILITY);
  const [moveRock] = useMutation(MOVE_ROCK);
  const [showSc, setShowSc] = useState(JSON.parse(sessionStorage.getItem(`oneYearObjs.${id.toString()}.showSC`)) || false);
  const [progress, setProgress] = useState(0);

  const itemType = "rock";
  const { confirmOpen, handleConfirmOpen, handleDelete } = useConfirmDelete({
    id,
    value,
    itemType,
  });

  const handleEditDialog = () => {
    const rockWithObj = { ...rock, objective: objectiveId };
    setDialog({ ...dialog, addRockDialog: { open: true, rock: rockWithObj, planId } });
  };

  const handleDuplicate = () => {
    setDialog({
      ...dialog,
      addRockDialog: {
        open: true,
        objective: objectiveId,
        quarter: index,
        value,
        users: [],
        duplicate: id,
        successCriterias: successCriterias.map((sc) => _.omit(sc, ["id"])),
        planId,
      },
    });
  };

  const handleAddIssueDialog =
    (referenceId = null, referenceModel = null, value = null, user = null) =>
    () => {
      setDialog({
        ...dialog,
        addTodoDialog: {
          open: true,
          category: "issue",
          referenceId,
          referenceModel,
          value,
          user,
          planId,
        },
      });
    };

  const handleUpdateStatus = (status) => async (e) => {
    e.stopPropagation();

    try {
      const res = await updateRockStatus({ variables: { id, status } });

      if (res.data.updateRockStatus) {
        snack(`"${value}" marked as ${status === "complete" ? "complete" : "incomplete"}`);
        requestFetch();
      }
    } catch (err) {
      snack("Failed to update rock status", "error");
    }
  };

  const handleUpdateHide = async (e) => {
    e.stopPropagation();

    try {
      const res = await updateRockVisibility({ variables: { id, hide: !hide } });

      if (res.data.updateRockVisibility) {
        snack(`"${value}" marked as ${hide ? "visible" : "hidden"}`);
        requestFetch();
      }
    } catch (err) {
      snack("Failed to update rock visibility", "error");
    }
  };

  const handleMoveRock = (isFutureRock) => async () => {
    try {
      const ok = await moveRock({ variables: { id, isFutureRock } });

      if (ok.data.moveRock) {
        snack(`Successfully moved the rock to ${isFutureRock ? "previous year" : "future year"}`);
        // window.location.reload();
        requestFetch();
      }
    } catch (err) {
      snack(`Failed to move the rock to ${isFutureRock ? "previous year" : "future year"}`, "error");
    }
  };

  const handleAddSuccessCriteriaDialog = () => {
    setDialog({
      ...dialog,
      addSuccessCriteriaDialog: { open: true, rock: id },
    });
  };

  const handleScEditDialog = (sc) => () => {
    if (!canEdit) return;
    setDialog({ ...dialog, addSuccessCriteriaDialog: { open: true, successCriteria: sc, planId, rock: id } });
  };

  const handleShowSC = () => {
    sessionStorage.setItem(`oneYearObjs.${id.toString()}.showSC`, JSON.stringify(!showSc));
    setShowSc((prev) => !prev);
  };

  useEffect(() => {
    const done = successCriterias.reduce((sum, sc) => (sc.done ? sum + 1 : sum), 0);
    setProgress(done);
  }, [successCriterias]);

  const percentageCompleted = (progress / successCriterias.length) * 100;
  const isComplete = (percentageCompleted === 100 || successCriterias.length === 0) && status === "complete";
  const droppableId = `SC_${id}_${rockPath}.successCriterias`;

  return (
    <>
      <List
        dense
        className={`${isDragging ? styles.listDragging : styles.list} ${percentageCompleted > 0 ? styles.hasRockProgress : ""} ${
          isComplete ? styles.listComplete : ""
        }`}
        id={`${id}-drag`}
        style={{ position: "relative" }}
      >
        <div {...provided.dragHandleProps} style={{ opacity: canEdit ? 1 : 0.25 }} className={styles.dragHandle}>
          <Icon path={mdiDrag} size={1} />
        </div>
        <ListItem>
          {hide && <Icon style={{ position: "absolute", right: 18, top: 0 }} path={mdiEyeOffOutline} size={0.75} />}
          <ListItemIcon style={{ flexDirection: "column" }}>
            <UserAvatars users={users} />
            {!_.isNil(plan?.departmentName) ? <PlanPill plan={plan} /> : <PlanPill plan={null} />}
          </ListItemIcon>
          <ListItemText onClick={handleShowSC} className={styles.cursor}>
            <div style={{ color: kanbanStatus === "completed" ? green[900] : red[300] }}>[{kanbanStatus}]</div>
            <div className={isComplete ? styles.complete : undefined}>{value}</div>
          </ListItemText>
          <NotesButton
            id={id}
            model="rock"
            value={value.trim()}
            user={_.get(users, "0") ? users[0].id : null}
            doc={rock}
            tabs={["notes", "issues", "todos"]}
            canEditTodo={canEdit}
            planId={planId}
          />
          {canEdit && (
            <Menu>
              {/* <MenuItem onClick={handleAddSuccessCriteriaDialog}>Add Success Criteria</MenuItem> */}
              {/* <MenuItem onClick={handleAddIssueDialog(id, "Rock", value.trim(), users[0] ? users[0].id : null)}>Add Issue</MenuItem> */}
              <MenuItem onClick={handleEditDialog}>Edit</MenuItem>
              <MenuItem onClick={handleUpdateStatus(isComplete ? "on track" : "complete")}>
                {isComplete ? "Revert complete" : "Complete"}
              </MenuItem>
              <MenuItem onClick={handleUpdateHide}>{hide ? "Show" : "Hide"}</MenuItem>
              <MenuItem onClick={handleDuplicate}>Copy</MenuItem>
              {isCurrentPlan && nextYearPlanCreated && index === 4 && (
                <MenuItem onClick={handleMoveRock(false)}>Move to Future Year</MenuItem>
              )}
              {isFuturePlan && index === 1 && <MenuItem onClick={handleMoveRock(true)}>Move to Current Year</MenuItem>}
              <MenuItem onClick={handleConfirmOpen(true)} className={styles.delete}>
                Delete
              </MenuItem>
            </Menu>
          )}
        </ListItem>

        <Droppable droppableId={droppableId} key={id} type={`successCriteria_${spid}`}>
          {(provided, snapshot) => {
            return (
              <Collapse
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={snapshot.isDraggingOver ? styles.droppableDragging : styles.droppable}
                in={showSc}
              >
                {_.isEmpty(successCriterias) ? (
                  <ListItem>
                    <ListItemText className={styles.listItemNoRock}>
                      No success criteria.{" "}
                      {canEdit && (
                        <u onClick={handleAddSuccessCriteriaDialog} className={styles.cursor}>
                          Add one
                        </u>
                      )}
                    </ListItemText>
                  </ListItem>
                ) : (
                  successCriterias.map((sc, idx) => {
                    const { id: scId, value, targetDate, done } = sc;
                    return (
                      <Draggable
                        key={`${scId}_${id}`}
                        draggableId={`SC_${scId}_${id}`}
                        index={idx}
                        isDragDisabled={!isAuthed(auth, "department facilitator") || !canEdit}
                      >
                        {(provided, snapshot) => {
                          return (
                            <>
                              <ListItem
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                                style={{ ...provided.draggableProps.style }}
                                key={`${scId}_${id}`}
                              >
                                <ListItemIcon className={styles.listIcon}>
                                  <div {...provided.dragHandleProps} style={{ opacity: canEdit ? 1 : 0.25 }}>
                                    <Icon path={mdiDrag} size={1} />
                                  </div>
                                  {done ? (
                                    <Icon path={mdiCheckboxBlankCircle} size={1} color={green[300]} />
                                  ) : (
                                    <Icon path={mdiDecagram} size={1} color={red[300]} />
                                  )}
                                </ListItemIcon>
                                <ListItemText className={done ? styles.complete : styles.listItem} onClick={handleScEditDialog(sc)}>
                                  <span>{value}</span>
                                  {targetDate && (
                                    <span className={before(targetDate, startOfToday()) ? styles.targetDatePast : styles.targetDate}>
                                      {` (${formatAs(targetDate, "MMM dd, yyyy")})`}
                                    </span>
                                  )}
                                </ListItemText>
                              </ListItem>
                              {rockScStrategy === "copy" && snapshot.isDragging && snapshot.draggingOver !== droppableId && (
                                <ListItem key={`${scId}_${id}`}>
                                  <ListItemIcon className={styles.listIcon}>
                                    {done ? (
                                      <Icon path={mdiCheckboxBlankCircle} size={1} color={green[300]} />
                                    ) : (
                                      <Icon path={mdiDecagram} size={1} color={red[300]} />
                                    )}
                                  </ListItemIcon>
                                  <ListItemText className={done ? styles.complete : styles.listItem}>{value}</ListItemText>
                                </ListItem>
                              )}
                            </>
                          );
                        }}
                      </Draggable>
                    );
                  })
                )}
                {snapshot.isDraggingOver && provided.placeholder}
              </Collapse>
            );
          }}
        </Droppable>

        <div className={`${styles.rockProgress} ${isComplete ? styles.rpComplete : ""}`} style={{ width: `${percentageCompleted}%` }}>
          {percentageCompleted > 0 && <div style={{ padding: "0 4px" }}>{Math.round(percentageCompleted)}%</div>}
        </div>
      </List>

      <ConfirmDeletionDialog
        itemType={itemType}
        value={value}
        confirmOpen={confirmOpen}
        handleConfirmOpen={handleConfirmOpen}
        handleDeletion={handleDelete(deleteRock)}
      />
    </>
  );
};

export default Rock;

const DELETE_ROCK = gql`
  mutation OneYearDeleteRock($id: ID!) {
    deleteRock(id: $id) {
      deletedRock {
        id
      }
      deletedSuccessCriterias {
        id
      }
      updatedObjective {
        id
        rocks {
          id
        }
      }
      updatedPlan {
        id
        rocks {
          id
        }
      }
    }
  }
`;

const UPDATE_ROCK_STATUS = gql`
  mutation ($id: ID!, $status: String!) {
    updateRockStatus(id: $id, status: $status)
  }
`;

const UPDATE_ROCK_VISIBILITY = gql`
  mutation ($id: ID!, $hide: Boolean!) {
    updateRockVisibility(id: $id, hide: $hide)
  }
`;

const MOVE_ROCK = gql`
  mutation ($id: ID!, $isFutureRock: Boolean!) {
    moveRock(id: $id, isFutureRock: $isFutureRock)
  }
`;
