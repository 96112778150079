import React, { useState, useEffect, useRef, useMemo } from "react";
import { useAuth } from "../../context/authContext";
import styles from "./AddEditTodoDialog.module.scss";
import _ from "lodash";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import useForm from "../../hooks/useForm";
import { useDepartmentFilter } from "../../context/departmentFilterContext";
import { isAuthed } from "../../utils/authorization";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Tooltip,
  useMediaQuery,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Autocomplete, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import Icon from "@mdi/react";
import {
  mdiLinkVariantOff,
  mdiRhombus,
  mdiRhombusSplit,
  mdiPoll,
  mdiBullseyeArrow,
  mdiAlertDecagram,
  mdiCheckboxMarked,
  mdiChartTimelineVariant,
} from "@mdi/js";

import SelectUsers from "../SelectUsers/SelectUsers";
import SelectDepartment from "../SelectDepartment/SelectDepartment";
import Loading from "../Loading/Loading";
import { Link } from "react-router-dom";
import PlanPill from "../PlanPill/PlanPill";
import { getPlansByReferenceModel, isValidDateInteger, recursiveMergeCustomizer } from "../../utils/misc";
import { TODO_FIELDS } from "../../utils/fragments";
import NewSelectDepartment from "../SelectDepartment/NewSelectDepartment";
import NewSelectUsers from "../SelectUsers/NewSelectUsers";

const PRIORITY = ["high", "medium", "low"];

const AddEditTodoDialog = ({ dialog, setDialog, requestFetch, fetch, params, org, snack }) => {
  const fs = useMediaQuery("(max-width: 600px)");
  const theme = useTheme();

  const fiscalYear = org.fiscalYear;

  const { auth } = useAuth();
  const isAdmin = isAuthed(auth, "department facilitator");

  const { departmentFilter } = useDepartmentFilter();
  const deptFilterSpid = _.get(departmentFilter, "sharedPlanId");

  const todoDialog = _.get(dialog, "addTodoDialog", {});
  const { open, todo, planId, category: ctgry, referenceId, referenceModel, value, user } = todoDialog;

  const isCreate = _.isNil(todo);

  const category = _.get(todo, "category", ctgry);
  const isIssue = category === "issue";

  const [close, setClose] = useState(true);

  const [searchTerm, setSearchTerm] = useState("");
  const [inputSearchTerm, setInputSearchTerm] = useState("");

  const debouncedSetSearchTermRef = useRef(_.debounce((newValue) => setSearchTerm(newValue), 700));

  const [createTodo, { loading }] = useMutation(CREATE_TODO);
  const [updateTodo] = useMutation(UPDATE_TODO);

  const [getReferencedTodo, { data: referencedTodoData, loading: referenceLoading }] = useLazyQuery(GET_REFERENCED_TODO_CREATE);

  const {
    data: oneYearCorpPlanData,
    refetch: oneYearCorpPlanDataRefetch,
    loading: oneYearCorpPlanDataLoading,
  } = useQuery(GET_ONE_YEAR_CORP_PLAN, {
    variables: {
      id: planId || null,
    },
  });

  const {
    data,
    refetch,
    loading: queryLoading,
  } = useQuery(GET_SC_ROCKS_OBJECTIVES_METRICS, {
    variables: {
      organization: params.org,
      sharedPlanId: deptFilterSpid,
      searchTerm,
      corpPlan: planId || null,
    },
  });

  const {
    data: userPlanData,
    refetch: userPlanDataRefetch,
    loading: userPlanDataLoading,
  } = useQuery(GET_USERS_PLANS, {
    variables: { organization: params.org, corpPlan: planId || null },
  });

  const {
    data: todosData,
    refetch: todosDataRefetch,
    loading: todosLoading,
    fetchMore: fetchMoreTodos,
  } = useQuery(GET_MORE_TODOS, {
    variables: {
      organization: params.org,
      sharedPlanId: deptFilterSpid,
      limit: 25,
      cursor: null,
      searchTerm,
      oneYearCorpPlan: planId || null,
    },
  });

  const {
    data: issueData,
    refetch: issueDataRefetch,
    loading: issuesLoading,
    fetchMore: fetchMoreIssues,
  } = useQuery(GET_MORE_ISSUES, {
    variables: {
      organization: params.org,
      sharedPlanId: deptFilterSpid,
      limit: 25,
      cursor: null,
      searchTerm,
      oneYearCorpPlan: planId || null,
    },
  });

  const handleClose = () => {
    setDialog({ ...dialog, addTodoDialog: { open: false } });
  };

  // FORMIK START
  const getSchema = (isIssue) => {
    return Yup.object().shape({
      value: Yup.string().required(`${isIssue ? "Issue" : "To do"} details are required`),
      referenceId: Yup.string().nullable(),
      referenceModel: Yup.string().nullable(),
      priority: Yup.string().required("Priority is required"), // should be fine without because init value is set to "medium"
      user: Yup.string().required("User is required"),
      plan: Yup.string().nullable(),
      done: Yup.boolean(),
    });
  };

  const getInitialValues = ({ todo, value, user, referenceId, referenceModel }) => {
    let initVals = {
      value: value || "",
      referenceId: referenceId || null,
      referenceModel: referenceModel || null,
      priority: "medium",
      user: user || "",
      plan: null,
      done: false,
    };

    if (!_.isNil(todo)) {
      initVals = _.mergeWith({}, initVals, todo, (objVal, srcVal) => recursiveMergeCustomizer(srcVal));
    }

    return initVals;
  };

  const formik = useFormik({
    initialValues: getInitialValues({ todo, value, user, referenceId, referenceModel }),
    validationSchema: getSchema(isIssue),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const { value, user, referenceId, referenceModel, priority, plan, done } = values;

      try {
        let res;
        if (isCreate) {
          res = await createTodo({
            variables: {
              organization: params.org,
              category,
              user: isAdmin ? user : auth.id,
              author: auth.id,
              priority,
              value,
              referenceId: referenceModel ? referenceId : null,
              referenceModel,
              plan,
            },
          });
        } else {
          res = await updateTodo({
            variables: {
              id: todo.id,
              value,
              user,
              referenceId,
              referenceModel,
              priority,
              plan,
              done,
            },
          });
        }

        if (res) {
          if (referenceId && isIssue) {
            snack(
              <>
                Issue Created! Click{" "}
                <Link style={{ color: "white", textDecoration: "none" }} to="issues">
                  <strong>here</strong>
                </Link>{" "}
                to view.
              </>
            );
          } else {
            snack(`${isCreate ? "Created" : "Updated"} "${value}" ${category}`);
          }

          if (close) {
            handleClose();
          } else {
            resetForm();
          }

          requestFetch();
        }
        setSubmitting(false);
      } catch (error) {
        snack(`Failed to ${isCreate ? "create" : "update"} ${category}`, "error");
      }
    },
    enableReinitialize: true,
  });

  const {
    values,
    errors,
    touched,
    handleSubmit: handleSubmitFormik,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    setValues,
    setFieldTouched,
    resetForm,
  } = formik;

  const isTodoReference = ["Todo", "Issue"].includes(values.referenceModel);

  const handleSubmit = (close) => {
    setClose(close);
    handleSubmitFormik();
  };

  const handleChangeReferenceId = (item) => {
    // change tied in reference id
    let newValues = _.cloneDeep(values);

    newValues.referenceId = _.get(item, "id", null);

    // also change department based on tied in selected
    const plans = getPlansByReferenceModel(values.referenceModel, item);
    const firstPlan = _.first(plans);

    if (!_.isNil(firstPlan) && _.isNil(values.plan)) {
      const sharedPlanId = _.get(firstPlan, "sharedPlanId");
      const targetPlan = _.find(_.get(userPlanData, "plans", []), (plan) => plan.sharedPlanId === sharedPlanId);

      if (targetPlan) {
        newValues.plan = _.get(targetPlan, "id", null);
      }
    }

    setValues(newValues);
  };

  // **NOTE: added to useFormik onSubmit
  // const handleSubmit = (close) => async () => {
  //   if (!validateForm()) return;

  //   const { value, referenceId, referenceModel, priority, plan } = form;

  //   try {
  //     const ok = await createTodo({
  //       variables: {
  //         organization: params.org,
  //         category,
  //         user: isAdmin ? form.user : auth.id,
  //         author: auth.id,
  //         priority,
  //         value,
  //         referenceId: referenceModel ? referenceId : null,
  //         referenceModel,
  //         plan,
  //       },
  //     });

  //     if (ok.data.createTodo) {
  //       if (referenceId && isIssue) {
  //         snack(
  //           <>
  //             Issue Created! Click{" "}
  //             <Link style={{ color: "white", textDecoration: "none" }} to="issues">
  //               <strong>here</strong>
  //             </Link>{" "}
  //             to view.
  //           </>
  //         );
  //       } else {
  //         snack(`Created "${value}" ${category}`);
  //       }
  //       requestFetch();
  //       // If this dialog was opened in with another object as reference, do not remove the reference
  //       resetForm({ ...form, value: null });

  //       if (close) {
  //         handleClose();
  //       }
  //     }
  //   } catch (err) {
  //     snack(`Failed to create ${category}`, "error");
  //   }
  // };

  const loadMoreItems = (event, referenceModel) => {
    const userScrolledToTheBottom = Math.abs(event.target.scrollHeight - event.target.clientHeight - event.target.scrollTop) < 1;
    if (isTodoReference && userScrolledToTheBottom) {
      //user is at the end of the list so load more items
      if (values.referenceModel === "Todo" && !_.isNil(_.get(todosData, "results.nextCursor"))) {
        fetchMoreTodos({
          variables: {
            organization: params.org,
            sharedPlanId: deptFilterSpid,
            limit: 25,
            cursor: todosData.results.nextCursor,
            searchTerm,
          },
        });
      } else if (values.referenceModel === "Issue" && !_.isNil(_.get(issueData, "results.nextCursor"))) {
        fetchMoreIssues({
          variables: {
            organization: params.org,
            sharedPlanId: deptFilterSpid,
            limit: 25,
            cursor: issueData.results.nextCursor,
            searchTerm,
          },
        });
      }
    }
  };

  const renderReferenceItem = (item) => {
    const { value, category } = item;
    const refType = values.referenceModel;

    let identifier = _.startCase(refType),
      extras;
    switch (refType) {
      case "Objective":
        identifier = _.get({ "1 year": "1YO", "3 year": "3YO" }, category, identifier);
        break;
      case "Metric":
        identifier = _.get({ year: "3YM", quarter: "1YM" }, category, identifier);
        break;
      case "Rock":
        extras = (
          <Typography
            noWrap
            variant="caption"
            className={styles.tieInItemText}
            style={{
              fontStyle: "italic",
              color: theme.palette.text.secondary,
            }}
          >
            {_.get(item, "objective.value")}
          </Typography>
        );
        break;
      default:
    }

    return (
      <div style={{ maxWidth: 430 }}>
        <Typography noWrap className={styles.tieInItemText}>
          <strong>{identifier}:</strong> {value}
        </Typography>
        {extras}
      </div>
    );
  };

  const isCurrentYear = useMemo(() => {
    if (!oneYearCorpPlanData) return false;

    const oneYearCorpPlanYear = _.get(oneYearCorpPlanData, "plan.year");
    return isValidDateInteger(oneYearCorpPlanYear) && isValidDateInteger(fiscalYear) && oneYearCorpPlanYear === fiscalYear;
  }, [oneYearCorpPlanData, fiscalYear]);

  const menuItems = useMemo(() => {
    if (_.isEmpty(data)) return [];

    let menuItemsArr = [];
    switch (values.referenceModel) {
      case "SuccessCriteria":
        menuItemsArr = _.get(data, "successCriterias", []);
        break;
      case "Rock":
        menuItemsArr = _.get(data, "rocks", []);
        break;
      case "Objective":
        menuItemsArr = _.get(data, "objectives", []);
        break;
      case "Metric":
        const metrics = _.get(data, "metrics", []);
        menuItemsArr = _.uniqBy(metrics, "value");
        break;
      case "WeeklyTarget":
        menuItemsArr = _.get(data, "weeklyTargets", []);
        break;
      case "Issue":
        menuItemsArr = _.get(issueData, "results.todos", []);
        break;
      case "Todo":
        menuItemsArr = _.get(todosData, "results.todos", []);
        break;
      default:
    }

    if (isTodoReference) {
      // if reference is a todo or issue, add the reference if it has not been lazy loaded yet
      if (!menuItemsArr.some((item) => item.id === values.referenceId)) {
        const referencedTodo = _.get(referencedTodoData, "todo");

        if (referencedTodo) menuItemsArr.unshift(referencedTodo);
      }
    } else {
      // sort if the reference isn't a todo or issue which are lazy loaded
      const deptPath = _.get(
        { SuccessCriteria: "rock.plan.departmentName", WeeklyTarget: "plans.0.departmentName" },
        values.referenceModel,
        "plan.departmentName"
      );
      const fieldArr = [deptPath, "value"];
      const orderArr = ["asc", "asc"];

      if (["Objective", "Metric"].includes(values.referenceModel)) {
        fieldArr.unshift("category");
        orderArr.unshift("desc");
      }

      menuItemsArr = _.orderBy(menuItemsArr, fieldArr, orderArr);
    }

    return menuItemsArr;
  }, [values.referenceModel, values.referenceId, data, issueData, todosData, referencedTodoData, isTodoReference]);

  useEffect(() => {
    if (debouncedSetSearchTermRef.current) {
      debouncedSetSearchTermRef.current.cancel();
      debouncedSetSearchTermRef.current(inputSearchTerm);
    }
  }, [inputSearchTerm]);

  useEffect(() => {
    if (open) {
      refetch();
    } else {
      resetForm();
    }
  }, [fetch, open, refetch, resetForm]);

  useEffect(() => {
    if (open && isCreate && userPlanData && deptFilterSpid) {
      const plans = _.get(userPlanData, "plans", []);

      const planWithSameSpid = _.find(plans, (plan) => plan.sharedPlanId === deptFilterSpid);
      setFieldValue("plan", _.get(planWithSameSpid, "id", null));
    }
  }, [open, isCreate, userPlanData, deptFilterSpid, setFieldValue]);

  useEffect(() => {
    if (open && userPlanData && referencedTodoData) {
      const plans = _.get(userPlanData, "plans", []);
      const ref = _.get(referencedTodoData, "todo");

      if (ref && !_.isEmpty(plans)) {
        // set department based on referenceId
        const sharedPlanId = _.get(ref, "plan.sharedPlanId");
        const targetPlan = _.find(plans, (plan) => plan.sharedPlanId === sharedPlanId);

        if (targetPlan) {
          setFieldValue("plan", _.get(targetPlan, "id"));
        }
      }
    }
  }, [open, userPlanData, referencedTodoData, setFieldValue]);

  useEffect(() => {
    if (isTodoReference) {
      getReferencedTodo({
        variables: {
          id: referenceId,
        },
      });
    }
  }, [isTodoReference, referenceId, getReferencedTodo]);

  const selectedItem = menuItems.find((item) => item.id === values.referenceId);

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      fullWidth
      fullScreen={fs}
    >
      <DialogTitle>
        <div className={styles.title}>
          {isCreate ? "Create" : "Edit"} {isIssue ? "Issue" : "To Do"}
          <div>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <FormikProvider value={formik}>
          <TextField
            autoFocus
            multiline
            fullWidth
            label={`${isIssue ? "Issue" : "To Do"} Details`}
            variant="outlined"
            margin="normal"
            {...getFieldProps("value")}
            error={Boolean(touched.value && errors.value)}
            helperText={touched.value && errors.value}
          />

          {/* <TextField
          select
          fullWidth
          label="Priority"
          variant="outlined"
          margin="normal"
          {...getFieldProps("priority")}
          error={Boolean(touched.priority && errors.priority)}
          helperText={touched.priority && errors.priority}
        >
          {PRIORITY.map((value) => {
            return (
              <MenuItem key={value} value={value}>
                {_.startCase(value)}
              </MenuItem>
            );
          })}
        </TextField> */}
          <div style={{ marginTop: theme.spacing(1), textAlign: "center" }}>
            <ToggleButtonGroup
              exclusive
              value={values.priority}
              onChange={(e, value) => {
                setFieldTouched("priority");
                if (value !== null) {
                  setFieldValue("priority", value);
                }
              }}
              style={{
                flexWrap: "wrap",
                gap: theme.spacing(1),
                justifyContent: "center",
              }}
            >
              {PRIORITY.map((priority) => {
                return (
                  <ToggleButton key={priority} value={priority} style={{ border: `1px solid ${theme.palette.grey["A100"]}` }}>
                    {_.startCase(priority)}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
            {Boolean(touched.priority && errors.priority) && (
              <Typography
                variant="caption"
                style={{
                  display: "block",
                  color: theme.palette.error.main,
                  textAlign: "center",
                }}
              >
                {errors.priority}
              </Typography>
            )}
          </div>

          {userPlanData && (
            <>
              <NewSelectDepartment
                showAll
                buttonGroup
                name="plan"
                plans={_.get(userPlanData, "plans")}
                formik={formik}
                disabled={!_.isNil(deptFilterSpid)}
              />
              <NewSelectUsers
                buttonGroup
                name="user"
                users={_.get(userPlanData, "users")}
                handleResetField={() => setFieldValue("user", "")}
                helperText={`Who is accountable for this  ${isIssue ? "issue" : "todo"}?`}
                plan={values.plan}
                allPlans={_.get(userPlanData, "plans")}
                formik={formik}
                debug="todos"
              />
            </>
          )}
          <div className={styles.modelButtons}>
            <ToggleButtonGroup
              exclusive
              value={values.referenceModel}
              onChange={(e, value) => {
                setFieldValue("referenceModel", value);
                setInputSearchTerm("");
              }}
            >
              <ToggleButton value={null} disabled={!isCurrentYear}>
                <span className={styles.toggleButtonLabel}>No Tie In</span>
                {/* <Tooltip title="No tie in"> */}
                <Icon path={mdiLinkVariantOff} size={1.25} />
                {/* </Tooltip> */}
              </ToggleButton>
              <ToggleButton value="SuccessCriteria" disabled={!isCurrentYear}>
                <span className={styles.toggleButtonLabel}>Suc. Crit.</span>
                {/* <Tooltip title="Success criteria tie in"> */}
                <Icon path={mdiRhombusSplit} size={1.25} />
                {/* </Tooltip> */}
              </ToggleButton>
              <ToggleButton value="Rock" disabled={!isCurrentYear}>
                <span className={styles.toggleButtonLabel}>Rock</span>
                {/* <Tooltip title="Rock tie in"> */}
                <Icon path={mdiRhombus} size={1.25} />
                {/* </Tooltip> */}
              </ToggleButton>
              <ToggleButton value="Objective" disabled={!isCurrentYear}>
                <span className={styles.toggleButtonLabel}>Obj.</span>
                {/* <Tooltip title="Objective tie in"> */}
                <Icon path={mdiChartTimelineVariant} size={1.25} />
                {/* </Tooltip> */}
              </ToggleButton>
              <ToggleButton value="Metric" disabled={!isCurrentYear}>
                <span className={styles.toggleButtonLabel}>Metric</span>
                {/* <Tooltip title="Metric tie in"> */}
                <Icon path={mdiPoll} size={1.25} />
                {/* </Tooltip> */}
              </ToggleButton>
              <ToggleButton value="WeeklyTarget" disabled={!isCurrentYear}>
                <span className={styles.toggleButtonLabel}>KPI</span>
                {/* <Tooltip title="KPI tie in"> */}
                <Icon path={mdiBullseyeArrow} size={1.25} />
                {/* </Tooltip> */}
              </ToggleButton>
              <ToggleButton value="Issue" disabled={!isCurrentYear}>
                <span className={styles.toggleButtonLabel}>Issue</span>
                {/* <Tooltip title="Issue tie in"> */}
                <Icon path={mdiAlertDecagram} size={1.25} />
                {/* </Tooltip> */}
              </ToggleButton>
              <ToggleButton value="Todo" disabled={!isCurrentYear}>
                <span className={styles.toggleButtonLabel}>To do</span>
                {/* <Tooltip title="Todo tie in"> */}
                <Icon path={mdiCheckboxMarked} size={1.25} />
                {/* </Tooltip> */}
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          {values.referenceModel && (
            <Autocomplete
              disabled={!isCurrentYear}
              options={menuItems}
              value={selectedItem || inputSearchTerm}
              getOptionLabel={(option) => _.get(option, "value", option) || ""}
              renderOption={(option, state) => {
                const { id } = option;
                const plans = getPlansByReferenceModel(values.referenceModel, option);
                const uniqPlans = _.uniqBy(plans, (plan) => plan.sharedPlanId);
                return (
                  <div value={id} className={styles.tieInItem}>
                    {uniqPlans.map((plan, idx) => (
                      <PlanPill plan={plan} key={idx} />
                    ))}
                    {_.isEmpty(uniqPlans) && <PlanPill plan={null} />}
                    {renderReferenceItem(option)}
                  </div>
                );
              }}
              ListboxProps={{
                onScroll: (e) => loadMoreItems(e, values.referenceModel),
              }}
              fullWidth
              onChange={(e, newValue) => handleChangeReferenceId(newValue)}
              onInputChange={(e, newInputValue) => setInputSearchTerm(newInputValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ marginTop: "16px" }}
                  variant="outlined"
                  label={`Tied In ${_.startCase(values.referenceModel)}`}
                />
              )}
            />
          )}
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        {isCreate ? (
          <Button
            onClick={() => handleSubmit(false)}
            color="primary"
            variant="outlined"
            disabled={loading || isSubmitting}
            className={styles.button}
          >
            {loading ? <Loading size={24} color="#fff" /> : "Create & Add Another"}
          </Button>
        ) : (
          <Button
            onClick={() => {
              setFieldValue("done", !values.done);
              setTimeout(() => {
                handleSubmit(true);
              }, 0);
            }}
            color="primary"
            style={{ marginRight: "auto" }}
          >
            {loading ? <Loading size={24} color="primary" /> : `Mark as ${values.done ? "Incomplete" : "Complete"}`}
          </Button>
        )}
        <Button
          onClick={() => handleSubmit(true)}
          color="primary"
          variant="contained"
          disabled={loading || isSubmitting}
          className={styles.button}
        >
          {loading ? <Loading size={24} color="#fff" /> : isCreate ? "Create" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditTodoDialog;

const GET_ONE_YEAR_CORP_PLAN = gql`
  query ($id: ID) {
    plan(id: $id) {
      year
    }
  }
`;

const GET_USERS_PLANS = gql`
  query AddTodoDialog_GetUsersPlans($organization: ID!, $corpPlan: ID) {
    users(organization: $organization) {
      name {
        first
        last
      }
      profilePicture
      id
      plan {
        id
        departmentName
        sharedPlanId
      }
    }
    plans(organization: $organization, category: "1 year", oneYearCorpPlan: $corpPlan) {
      id
      departmentName
      sharedPlanId
      year
    }
  }
`;

const GET_SC_ROCKS_OBJECTIVES_METRICS = gql`
  query AddTodoDialog_GetScRocksObjectivesMetrics($organization: ID!, $sharedPlanId: ID, $searchTerm: String, $corpPlan: ID) {
    successCriterias(organization: $organization, searchTerm: $searchTerm, oneYearCorpPlan: $corpPlan, currQuarter: true) {
      id
      value
      rock {
        id
        plan {
          id
          departmentName
          color
          shortName
          sharedPlanId
        }
      }
    }

    rocks(
      organization: $organization
      sharedPlanId: $sharedPlanId
      searchTerm: $searchTerm
      oneYearCorpPlan: $corpPlan
      currQuarter: true
    ) {
      id
      value
      index
      objective {
        id
        value
      }
      plan {
        id
        shortName
        color
        departmentName
        sharedPlanId
      }
    }

    objectives(
      organization: $organization
      sharedPlanId: $sharedPlanId
      searchTerm: $searchTerm
      corpPlan: $corpPlan
      includeThreeYear: true
    ) {
      id
      value
      category
      plan {
        id
        year
        shortName
        color
        departmentName
        sharedPlanId
      }
    }

    metrics(
      organization: $organization
      sharedPlanId: $sharedPlanId
      searchTerm: $searchTerm
      corpPlan: $corpPlan
      includeThreeYear: true
    ) {
      id
      value
      category
      plan {
        id
        year
        shortName
        color
        departmentName
        sharedPlanId
      }
    }

    weeklyTargets(organization: $organization, sharedPlanId: $sharedPlanId, searchTerm: $searchTerm, oneYearCorpPlan: $corpPlan) {
      id: _id
      value
      plans {
        year
        shortName
        color
        departmentName
        sharedPlanId
      }
    }
  }
`;

const GET_MORE_TODOS = gql`
  query AddTodoDialog_GetMoreTodos(
    $organization: ID!
    $sharedPlanId: ID
    $cursor: String
    $limit: Int
    $searchTerm: String
    $oneYearCorpPlan: ID
  ) {
    results: moreTodos(
      organization: $organization
      category: "todo"
      cursor: $cursor
      limit: $limit
      searchTerm: $searchTerm
      sharedPlanId: $sharedPlanId
      oneYearCorpPlan: $oneYearCorpPlan
    ) {
      todos {
        id: _id
        value
        plan {
          id
          shortName
          color
          departmentName
          sharedPlanId
        }
      }
      nextCursor
    }
  }
`;

const GET_MORE_ISSUES = gql`
  query AddTodoDialog_GetMoreIssues(
    $organization: ID!
    $sharedPlanId: ID
    $cursor: String
    $limit: Int
    $searchTerm: String
    $oneYearCorpPlan: ID
  ) {
    results: moreTodos(
      organization: $organization
      category: "issue"
      cursor: $cursor
      limit: $limit
      searchTerm: $searchTerm
      sharedPlanId: $sharedPlanId
      oneYearCorpPlan: $oneYearCorpPlan
    ) {
      todos {
        id: _id
        value
        plan {
          id
          shortName
          color
          departmentName
          sharedPlanId
        }
      }
      nextCursor
    }
  }
`;

const CREATE_TODO = gql`
  mutation AddTodoDialog_CreateTodo(
    $organization: ID!
    $value: String!
    $category: String
    $user: ID!
    $author: ID!
    $referenceId: ID
    $referenceModel: String
    $priority: String
    $plan: ID
  ) {
    createTodo(
      organization: $organization
      value: $value
      category: $category
      user: $user
      author: $author
      referenceId: $referenceId
      referenceModel: $referenceModel
      priority: $priority
      plan: $plan
    )
  }
`;

const GET_REFERENCED_TODO_CREATE = gql`
  query AddTodoDialog_GetRefTodoCreate($id: ID) {
    todo(id: $id) {
      id: _id
      value
      plan {
        id
        shortName
        color
        departmentName
        sharedPlanId
      }
    }
  }
`;

const UPDATE_TODO = gql`
  ${TODO_FIELDS}
  mutation TodosEditDialog_UpdateTodo(
    $id: ID!
    $value: String
    $done: Boolean
    $user: ID!
    $referenceId: ID
    $referenceModel: String
    $priority: String
    $plan: ID
  ) {
    updateTodo(
      id: $id
      value: $value
      done: $done
      user: $user
      referenceId: $referenceId
      referenceModel: $referenceModel
      priority: $priority
      plan: $plan
    ) {
      todo {
        ...TodoFields
      }
      successCriterias {
        id
        todos {
          id: _id
        }
      }
      rocks {
        id
        todos {
          id: _id
        }
      }
      metrics {
        id
        todos {
          id: _id
        }
      }
      objectives {
        id
        todos {
          id: _id
        }
      }
    }
  }
`;
