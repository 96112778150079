import React, { useEffect, useState } from "react";
import styles from "./Dashboard.module.scss";
import _ from "lodash";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { useTheme, useMediaQuery, Typography, TextField, MenuItem } from "@material-ui/core";

import Overview from "./Overview";
import Calendar from "./Calendar";
import { Autocomplete, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useAuth } from "../../context/authContext";
import Kanban from "./Kanban";
import Menu from "../../components/Menu/Menu";

const TABS = ["Overview", "Kanban", "Calendar"];
const KANBAN_TYPES = ["todo", "issue", "rock", "successCriteria"];

const Dashboard = ({ params, org }) => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const { auth } = useAuth();
  const [selectedTab, setSelectedTab] = useState(TABS[2]);

  const [filterUser, setFilterUser] = useState();
  const [filterKanbanType, setFilterKanbanType] = useState();

  const { data, loading, refetch } = useQuery(GET_DATA, {
    variables: {
      organization: org.id,
    },
    fetchPolicy: "network-only",
  });

  const users = _.get(data, "users", []);

  useEffect(() => {
    if (users) {
      const myUser = _.find(users, ["id", auth.id]);
      if (myUser) {
        setFilterUser(myUser);
      }
    }
  }, [users]);

  return (
    <div
      className={styles.flexCols}
      style={{
        flex: 1,
        marginTop: -30,
        marginBottom: -30,
        overflowY: selectedTab === "Kanban" ? "hidden" : "unset",
      }}
    >
      <div
        className={styles.flexBetween}
        style={{
          margin: `${theme.spacing(3)}px ${theme.spacing(3)}px 0`,
          gap: theme.spacing(2),
          flexWrap: "wrap",
        }}
      >
        <ToggleButtonGroup
          exclusive
          value={selectedTab}
          onChange={(e, value) => {
            if (value !== null) {
              setSelectedTab(value);
            }
          }}
        >
          {TABS.map((tab) => (
            <ToggleButton size="small" key={tab} value={tab}>
              {tab}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <div className={styles.flexBetween} style={{ gap: theme.spacing(2) }}>
          {selectedTab === "Kanban" && (
            <Menu
              button={
                <Typography style={{ textTransform: "none" }}>Type: {filterKanbanType ? _.startCase(filterKanbanType) : "None"}</Typography>
              }
              buttonProps={{ variant: "outlined", size: "large" }}
              icon="menu"
              menuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }}
            >
              {KANBAN_TYPES.map((type) => (
                <MenuItem
                  key={type}
                  onClick={() => {
                    if (type === filterKanbanType) setFilterKanbanType();
                    else setFilterKanbanType(type);
                  }}
                  selected={type === filterKanbanType}
                >
                  {_.startCase(type)}
                </MenuItem>
              ))}
            </Menu>
          )}
          <Autocomplete
            options={users}
            value={filterUser}
            style={{ minWidth: 225 }}
            onChange={(e, val) => setFilterUser(val)}
            getOptionLabel={(user) => {
              const name = _.get(user, "name", {});
              return `${name.first} ${name.last}`;
            }}
            getOptionSelected={(option, val) => _.get(option, "id", 1) === _.get(val, "id", 2)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Please select user"
                margin="dense"
                style={{ backgroundColor: "white", borderRadius: 4, margin: 0 }}
                // inputProps={{ ...params.inputProps }}
              />
            )}
          />
        </div>
      </div>
      <div
        style={{
          overflowY: "auto",
          boxSizing: "border-box",
          flex: 1,
        }}
      >
        {selectedTab === "Overview" && <Overview org={org} filterUser={filterUser} />}
        {selectedTab === "Kanban" && <Kanban org={org} filterUser={filterUser} filterType={filterKanbanType} />}
        {selectedTab === "Calendar" && <Calendar org={org} filterUser={filterUser} />}
      </div>
    </div>
  );
};

export default Dashboard;

const GET_DATA = gql`
  query ($organization: ID!) {
    users(organization: $organization) {
      id
      name {
        first
        last
      }
      plan {
        id
        departmentName
        closed
        year
      }
      profilePicture
      position
      email
      auth
    }

    usersCount(organization: $organization)
  }
`;
