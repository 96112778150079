import React, { useEffect, useMemo } from "react";
import _ from "lodash";
import styles from "./SelectUsers.module.scss";
import { MenuItem, Avatar, TextField, Typography, useTheme } from "@material-ui/core";
import { useUser } from "../../context/userContext";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

const NewSelectUsers = ({
  name,
  label,
  helperText,
  formik,
  plan,
  allPlans = [],
  users = [],
  multiple = false,
  disabled = false,
  buttonGroup = false,
  handleResetField = () => {},
}) => {
  const theme = useTheme();
  const { user: loggedInUser } = useUser();

  const { values, errors, touched, getFieldProps, setFieldValue, setFieldTouched } = formik;

  const isTouched = _.get(touched, name);
  const error = _.get(errors, name);
  const userValue = _.get(values, name);

  const hasError = Boolean(isTouched && error);
  const helperTxt = hasError ? error : helperText;

  const usersToShow = useMemo(() => {
    if (_.isEmpty(plan)) {
      return [loggedInUser]; // not sure if this still makes sense
    }

    const planIds = allPlans.map((plan) => _.get(plan, "id", plan));
    const usersByPlan = _(users)
      .flatMap((user) =>
        _.get(user, "plan", []).map((userPlan) => ({
          planId: _.get(userPlan, "id", userPlan),
          user,
        }))
      )
      .filter((item) => planIds.includes(item.planId))
      .groupBy("planId")
      .mapValues((group) => group.map((item) => item.user))
      .value();

    return _.get(usersByPlan, plan, []);
  }, [plan, loggedInUser, users, allPlans]);

  useEffect(() => {
    const validUserIds = new Set(usersToShow.map((user) => user.id));

    if (!_.isEmpty(userValue)) {
      if (_.isArray(userValue)) {
        const allSelectedIdsValid = userValue.every((selectedUser) => validUserIds.has(selectedUser));

        if (!allSelectedIdsValid) {
          handleResetField();
        }
      } else if (!validUserIds.has(userValue)) {
        handleResetField();
      }
    }
  }, [userValue, usersToShow, handleResetField]);

  return buttonGroup ? (
    <div style={{ margin: `${theme.spacing(2)}px 0`, textAlign: "center" }}>
      <ToggleButtonGroup
        exclusive
        value={values[name]}
        onChange={(e, value) => {
          setFieldTouched(name);
          setFieldValue(name, value);
        }}
        style={{
          flexWrap: "wrap",
          gap: theme.spacing(1),
          justifyContent: "center",
        }}
      >
        {!_.isEmpty(usersToShow) &&
          usersToShow.map(({ name: username, profilePicture, id }) => (
            <ToggleButton key={id} value={id} style={{ border: `1px solid ${theme.palette.grey["A100"]}` }}>
              <div className={styles.item} style={{ gap: theme.spacing(1) }}>
                <Avatar className={styles.avatar} src={profilePicture}>
                  {username.first[0]}
                  {username.last[0]}
                </Avatar>
                <Typography variant="button">{`${username.first[0]}. ${username.last}`}</Typography>
              </div>
            </ToggleButton>
          ))}
      </ToggleButtonGroup>
      <Typography
        variant="caption"
        style={{
          display: "block",
          color: hasError ? theme.palette.error.main : "unset",
          textAlign: "center",
        }}
      >
        {helperTxt}
      </Typography>
    </div>
  ) : (
    <TextField
      select
      fullWidth
      label={label || "Person Accountable"}
      variant="outlined"
      margin="normal"
      style={{ opacity: disabled ? 0.5 : 1 }}
      {...getFieldProps(name)}
      SelectProps={{
        multiple,
        ...(multiple
          ? {
              renderValue: (selected) => `${selected.length} User(s) Selected`,
            }
          : {}),
      }}
      error={hasError}
      helperText={helperTxt}
      disabled={disabled}
    >
      {!_.isEmpty(usersToShow) ? (
        usersToShow.map(({ name: username, profilePicture, id }) => {
          return (
            <MenuItem key={id} value={id}>
              <div className={styles.item} style={{ gap: theme.spacing(1) }}>
                <Avatar className={styles.avatar} src={profilePicture}>
                  {username.first[0]}
                  {username.last[0]}
                </Avatar>
                <Typography>{`${username.first} ${username.last}`}</Typography>
              </div>
            </MenuItem>
          );
        })
      ) : (
        <MenuItem value={""}>No users found for selected department(s)</MenuItem>
      )}
    </TextField>
  );
};

export default NewSelectUsers;
