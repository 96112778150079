import React, { useEffect, useContext, useState } from "react";
import { useAuth } from "../../context/authContext";
import { useDepartmentFilter } from "../../context/departmentFilterContext";
import styles from "./AddEditSuccessCriteriaDialog.module.scss";
import _ from "lodash";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";
import { SUCCESS_CRITERIA_FIELDS } from "../../utils/fragments";
import { recursiveMergeCustomizerWithSkipRetain } from "../../utils/misc";

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  Menu,
  MenuItem,
  Tooltip,
  CircularProgress,
  useTheme,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import CloseIcon from "@material-ui/icons/Close";
import { mdiBrain, mdiTypewriter, mdiUndoVariant } from "@mdi/js";
import Icon from "@mdi/react";

import Loading from "../Loading/Loading";
import NewSelectRock from "../SelectRock/NewSelectRock";
import ConfirmDeletionDialog from "../ConfirmDeletionDialog/ConfirmDeletionDialog";
import useConfirmDelete from "../../hooks/useConfirmDelete";
import useLLM from "../../hooks/useLLM";
import LLMSuggestionsListDialog from "../LLMDialogs/LLMSuggestionsListDialog";
import LLMSettingsDialog from "../LLMDialogs/LLMSettingsDialog";
import { isAuthed } from "../../utils/authorization";

// const initForm = {
//   value: null,
//   rock: null,
// };

// const initErrorForm = {
//   value: ["required"],
//   rock: ["required"],
// };

const ITEM_TYPE = "success criteria";

const AddEditSuccessCriteriaDialog = ({ dialog, setDialog, params, org, snack, requestFetch }) => {
  const theme = useTheme();
  const fs = useMediaQuery("(max-width: 600px)");

  const { auth } = useAuth();
  const isAdmin = isAuthed(auth, "vantage facilitator");

  const { departmentFilter } = useDepartmentFilter();
  const deptFilterSpid = _.get(departmentFilter, "sharedPlanId");

  const successCriteriaDialog = _.get(dialog, "addSuccessCriteriaDialog", {});

  const {
    open,
    rock,
    successCriteria,
    planId, // only passed when editing (i.e. when successCriteria is provided) for the issue dialog
    rockDialog,
  } = successCriteriaDialog;

  const isCreate = _.isNil(successCriteria);
  const isRockDialog = !_.isNil(rockDialog);

  const [close, setClose] = useState(true);
  const [prevValue, setPrevValue] = useState("");

  const { data, refetch } = useQuery(GET_ROCKS, {
    variables: { organization: params.org },
  });

  const [createSuccessCriteria, { loading }] = useMutation(CREATE_SUCCESS_CRITERIA);
  const [deleteSuccessCriteria] = useMutation(DELETE_SUCCESS_CRITERIA, {
    update(cache, { data: { deleteSuccessCriteria } }) {
      const { deletedSuccessCriteria } = deleteSuccessCriteria;
      const deleteScId = cache.identify(deletedSuccessCriteria);
      cache.modify({
        fields: {
          successCriterias: (existingScs) => {
            return existingScs.filter((scRef) => {
              const scId = cache.identify(scRef);
              return scId !== deleteScId;
            });
          },
        },
      });
    },
  });
  const [updateSuccessCriteria] = useMutation(UPDATE_SUCCESS_CRITERIA);

  const {
    llmSettingsOpen,
    setLlmSettingsOpen,
    canUndo,
    setCanUndo,
    rephraseText,
    rephraseTextData,
    rephraseTextLoading,
    llmSettingsByPurpose,
    handleSaveLLMSettings,
  } = useLLM({
    open,
    snack,
  });

  const handleClose = () => {
    setDialog({ ...dialog, addSuccessCriteriaDialog: { open: false } });
  };

  // FORMIK START
  const getSchema = (rock, isRockDialog) =>
    Yup.object().shape({
      value: Yup.string().required("Success criteria description is required"),
      rock: Yup.string().when([], {
        is: () => _.isNil(rock) && isRockDialog, // if we are creating a rock in the rock dialog then the rock tie-in for the sc is not required
        then: (schema) => schema,
        otherwise: (schema) => schema.required("Tied in rock is required"),
      }),
      targetDate: Yup.date().nullable(),
      done: Yup.boolean(),
    });

  const getInitialValues = (successCriteria, rockId) => {
    let initVals = {
      value: "",
      rock: rockId || "",
      targetDate: null,
      done: false,
    };

    if (!_.isNil(successCriteria)) {
      const targetDate = _.get(successCriteria, "targetDate", null);
      if (_.isString(targetDate)) {
        _.set(successCriteria, "targetDate", new Date(parseInt(targetDate)));
      }

      initVals = _.mergeWith({}, initVals, successCriteria, (objVal, srcVal, key) =>
        recursiveMergeCustomizerWithSkipRetain({ srcVal, fieldName: key, skipFields: ["targetDate"] })
      );
    }

    return initVals;
  };

  const formik = useFormik({
    initialValues: getInitialValues(successCriteria, rock),
    validationSchema: getSchema(rock, isRockDialog),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const { value, rock, targetDate, done } = values;

      try {
        let res;
        if (isCreate) {
          res = await createSuccessCriteria({
            variables: {
              organization: params.org,
              rock,
              value,
              targetDate,
            },
          });
        } else {
          res = await updateSuccessCriteria({ variables: { id: _.get(successCriteria, "id"), value, targetDate, done } });
        }

        if (res) {
          snack(`${isCreate ? "Created" : "Updated"} "${value}" success criteria`);
          if (close) {
            handleClose(res.data.createRock);
          } else {
            resetForm();
          }
          requestFetch();
        }
        setSubmitting(false);
      } catch (error) {
        snack(`Failed to ${isCreate ? "create" : "update"} "${value}" success criteria`, "error");
      }
    },
    enableReinitialize: true,
  });

  const {
    values,
    errors,
    touched,
    handleSubmit: handleSubmitFormik,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    setValues,
    resetForm,
    validateForm,
  } = formik;

  const { confirmOpen, handleConfirmOpen, handleDelete } = useConfirmDelete({
    id: _.get(successCriteria, "id"),
    value: values.value,
    itemType: ITEM_TYPE,
  });

  const handleSubmit = async (close) => {
    if (isRockDialog) {
      const rockDialogHandleSubmit = _.get(rockDialog, "handleSubmit");
      const errors = await validateForm();

      if (_.isEmpty(errors)) {
        rockDialogHandleSubmit(values);
        if (close) handleClose();
      }
    } else {
      setClose(close);
      handleSubmitFormik();
    }
  };

  const handleClickDelete = () => {
    if (!isRockDialog) handleConfirmOpen(true)();
  };

  const handleConfirmDelete = async () => {
    let successfulDeletion = await handleDelete(deleteSuccessCriteria)();
    if (successfulDeletion) {
      handleClose();
    }
  };

  // only available when editing success criteria
  const handleAddIssueDialog =
    (referenceId = null, referenceModel = null, value = null, user = null) =>
    () => {
      setDialog({
        ...dialog,
        addTodoDialog: {
          open: true,
          category: "issue",
          referenceId,
          referenceModel,
          value,
          user,
          planId,
        },
      });
    };

  const handleRephraseText = () => {
    setPrevValue(_.get(values, "value", ""));
    setCanUndo(true);

    rephraseText({
      variables: { text: values.value },
    });
  };

  const handleUndo = () => {
    setValues({ ...values, value: prevValue });
    setCanUndo(false);
  };

  useEffect(() => {
    if (open) {
      refetch();
    } else {
      resetForm();
    }
  }, [open]);

  useEffect(() => {
    if (rephraseTextData) {
      setFieldValue("value", _.get(rephraseTextData, "rephrase", ""));
    }
  }, [rephraseTextData]);

  const llmLoading = rephraseTextLoading;

  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        fullWidth
        fullScreen={fs}
      >
        <DialogTitle>
          <div className={styles.title}>
            <div style={{ display: "flex", gap: theme.spacing(2), alignItems: "center" }}>
              {isCreate ? "Create" : "Edit"} Success Criteria
              {isAdmin && (
                <Button
                  color="primary"
                  variant="outlined"
                  // onClick={(e) => handleOpenAdminMenu(e)}
                  onClick={() => setLlmSettingsOpen("rephrase")}
                >
                  Rephrase Settings
                </Button>
              )}
            </div>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <FormikProvider>
            {isCreate && !isRockDialog && (
              <NewSelectRock name="rock" rocks={_.get(data, "rocks", null)} sharedPlanId={deptFilterSpid} formik={formik} />
            )}
            <TextField
              autoFocus
              multiline
              fullWidth
              label="Success Criteria Description"
              variant="outlined"
              margin="normal"
              {...getFieldProps("value")}
              error={Boolean(touched.value && errors.value)}
              helperText={touched.value && errors.value}
              InputProps={{
                endAdornment: (
                  <>
                    <Tooltip title="Rephrase">
                      <div>
                        <IconButton
                          onClick={(e) => handleRephraseText()}
                          disabled={_.isEmpty(values.value) || llmLoading}
                          className={styles.llmButton}
                        >
                          {rephraseTextLoading ? <CircularProgress size={20} color="inherit" /> : <Icon path={mdiTypewriter} size={1} />}
                        </IconButton>
                      </div>
                    </Tooltip>
                    <Tooltip title="Undo">
                      <div>
                        <IconButton onClick={handleUndo} disabled={!canUndo || llmLoading} className={styles.llmButton}>
                          <Icon path={mdiUndoVariant} size={1} />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </>
                ),
              }}
            />
            <DatePicker
              autoOk
              clearable
              fullWidth
              inputVariant="outlined"
              format="dd/MM/yyyy"
              margin="normal"
              label="Target Date (optional)"
              {...getFieldProps("targetDate")}
              onChange={(dateVal) => setFieldValue("targetDate", dateVal)}
            />
          </FormikProvider>
        </DialogContent>
        <DialogActions>
          {isCreate ? (
            <>
              <Button onClick={handleClose}>Cancel</Button>
              {!isRockDialog && (
                <Button onClick={() => handleSubmit(false)} color="primary" variant="outlined" disabled={loading} className={styles.button}>
                  {loading ? <Loading size={24} color="#fff" /> : "Create & Add Another"}
                </Button>
              )}
            </>
          ) : (
            <>
              <Button
                onClick={handleAddIssueDialog(_.get(successCriteria, "id"), "SuccessCriteria", values.value)}
                color="primary"
                disabled={!_.has(successCriteria, "id")}
              >
                Add Issue
              </Button>
              <Button
                onClick={() => {
                  setFieldValue("done", !values.done);
                  setTimeout(() => {
                    handleSubmit(true);
                  }, 0);
                }}
                color="primary"
                style={{ marginRight: "auto" }}
              >
                {loading ? <Loading size={24} color="primary" /> : `Mark as ${values.done ? "Incomplete" : "Complete"}`}
              </Button>
              <Button onClick={handleClose}>Cancel</Button>
              {!isRockDialog && (
                <Button onClick={handleClickDelete} color="primary">
                  Delete
                </Button>
              )}
            </>
          )}
          <Button onClick={() => handleSubmit(true)} color="primary" variant="contained" disabled={loading} className={styles.button}>
            {isSubmitting || loading ? <Loading size={24} color="#fff" /> : isCreate ? "Create" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDeletionDialog
        itemType={ITEM_TYPE}
        value={values.value}
        confirmOpen={confirmOpen}
        handleConfirmOpen={handleConfirmOpen}
        handleDeletion={handleConfirmDelete}
      />
      <LLMSettingsDialog
        open={!_.isEmpty(llmSettingsOpen)}
        onClose={() => setLlmSettingsOpen("")}
        type="successCriteria"
        purpose={llmSettingsOpen}
        title={`LLM System Settings (${_.capitalize(llmSettingsOpen)})`}
        llmSettingsArr={_.get(llmSettingsByPurpose, llmSettingsOpen, [])}
        handleSave={handleSaveLLMSettings}
      />
    </>
  );
};

export default AddEditSuccessCriteriaDialog;

const GET_ROCKS = gql`
  query AddScDialog_GetRocks($organization: ID!) {
    rocks(organization: $organization) {
      id
      value
      plan {
        id
        departmentName
        color
        shortName
        sharedPlanId
      }
    }
  }
`;

const CREATE_SUCCESS_CRITERIA = gql`
  ${SUCCESS_CRITERIA_FIELDS}
  mutation CreateSuccessCriteria($value: String!, $rock: ID!, $organization: ID!) {
    createSuccessCriteria(value: $value, rock: $rock, organization: $organization) {
      updatedRock {
        id
        successCriterias {
          ...SuccessCriteriaFields
        }
      }
    }
  }
`;

const DELETE_SUCCESS_CRITERIA = gql`
  mutation DialogDeleteSuccessCriteria($id: ID!) {
    deleteSuccessCriteria(id: $id) {
      deletedSuccessCriteria {
        id
      }
      updatedRock {
        id
        successCriterias {
          id
        }
      }
    }
  }
`;

const UPDATE_SUCCESS_CRITERIA = gql`
  mutation ($id: ID!, $value: String, $targetDate: String, $done: Boolean) {
    updateSuccessCriteria(id: $id, value: $value, targetDate: $targetDate, done: $done)
  }
`;

// const GET_SC_SUGGESTIONS = gql`
//   query ($organization: ID!, $user: ID!, $rock: ID!, $feedbackPrompts: [ID!]) {
//     successCriteriaSuggestions(organization: $organization, user: $user, rock: $rock, feedbackPrompts: $feedbackPrompts) {
//       feedbackPrompt {
//         id
//         title
//       }
//       suggestions {
//         value
//       }
//       systemText
//       userText
//     }
//   }
// `;
